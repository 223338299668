import CommonSearch, {
    applyModals,
    clearModals,
    getSuggestionsWrapper,
    handleArrow,
    isMobileSearch,
    parseHtml,
    positionSuggestions,
    tearDownSuggestions,
    toggleSuggestionsIcon,
} from 'common/components/search';
import { debounce } from 'lodash';
import { onProductsImpression, onPromosImpression } from 'common/seo/gtmEvents';

const $clearButtonParent = $('.site-search .clear-button').parent('.input-group-append');
const $resetButtonParent = $('.site-search .reset-button').parent('.input-group-append');

function hideSuggestionSearch() {
    $('.search-manual').removeClass('d-flex').addClass('d-none');
}

function showSuggestionSearch() {
    $('.search-manual').addClass('d-flex').removeClass('d-none');
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    const $suggestionsWrapper = getSuggestionsWrapper($('input.search-field')).empty();
    $.spinner().stop();

    const parsedResponse = parseHtml(response);

    if (typeof (parsedResponse) !== 'object') {
        $suggestionsWrapper.append(parsedResponse).show();
        hideSuggestionSearch();
        $(this).siblings('.reset-button').addClass('d-sm-block');
        positionSuggestions(this);

        if (isMobileSearch(this)) {
            toggleSuggestionsIcon('close');
            applyModals(this);
        }
        const gtmSuggestions = $($suggestionsWrapper.find('.suggestions__product')[0]).data('gtm');
        if (gtmSuggestions) {
            window.gtmSuggestionsProducts = gtmSuggestions.gtmProductsArray;
            window.gtmSuggestionsPromotions = gtmSuggestions.gtmPromotionsArray;
            onProductsImpression([gtmSuggestions.gtmProductsArray], window.currency);
            onPromosImpression([gtmSuggestions.gtmPromotionsArray]);
        }
        $('.search-options_radio').on('change', (e) => {
            window.eventRadioFilter(e);
        });

        // Trigger screen reader by setting aria-describedby with the new suggestion message.
        const suggestionsList = $('.suggestions .item');
        if ($(suggestionsList).length) {
            $('input.search-field').attr('aria-describedby', 'search-result-count');
        } else {
            $('input.search-field').removeAttr('aria-describedby');
        }
        const headerHeight = $('.page-header').innerHeight() + $('.pre-header').innerHeight();
        const formHeight = $('.js-form').innerHeight() + headerHeight;
        if ((formHeight >= window.innerHeight)) {
            if (window.breakpoints.atLeast('md')) {
                $('.js-form').css('height', `calc(100vh - ${headerHeight}px)`);
            } else if (window.breakpoints.atLeast('sm')) {
                $('.js-form').css('height', '100vh');
            } else {
                $('.js-form').css('height', '100%');
            }
        }
        $('body').trigger('searchbar:hasresults');
        $('body').trigger('searchbar:disablescroll');
    } else {
        $suggestionsWrapper.hide();
        $('body').trigger('searchbar:hasnotresults');
        $('body').trigger('searchbar:enablescroll');
    }
}

// suggestions manual
function toggleSuggestionSearchManual(show) {
    const $searchOption = $('.search-options_radio input:checked');
    const $selectedSuggestionManual = $('.search-manual').find(`[data-filter='${$searchOption.val()}']`);
    const $suggestionsManual = $('.search-manual').children();

    $suggestionsManual.find('div[data-filter]').addClass('d-none');
    if ($('.search-options_radio input:checked').length) {
        $selectedSuggestionManual.removeClass('d-none');
    } else {
        $('.search-manual').find('[data-filter=\'default\']').removeClass('d-none');
    }
    if (show) {
        showSuggestionSearch();
    }
}

// suggestion filter
function toggleSuggestionFilter(clear = false) {
    const $searchOption = $('.search-options_radio input:checked');
    const $suggestions = $('.suggestions__product-recommendations').children();
    const $selectedSuggestion = $('.suggestions__product-recommendations').find(`[data-filter='${$searchOption.val()}']`);
    $suggestions.addClass('d-none');
    $('.suggestions__product-recommendations').find('[data-filter=\'default\']').removeClass('d-none');
    if ($selectedSuggestion.length && !clear) {
        $selectedSuggestion.removeClass('d-none');
        $suggestions.not(`[data-filter='${$searchOption.val()}']`).addClass('d-none');
    } else {
        $searchOption.prop('checked', false);
    }
}

function getSuggestions(scope) {
    const endpoint = $('.suggestions-wrapper').data('url');
    const minChars = 3;
    let savedFilter = $('.searchbar-open').data('saved-filter');
    if (!savedFilter) {
        const $searchOption = $('.search-options_radio input:checked');
        savedFilter = $searchOption.length ? $searchOption.val() : null;
    } else {
        $(`.search-options_radio input[value='${savedFilter}']`).prop('checked', true);
    }

    if ($(scope).val().length >= minChars && $(scope).closest('.site-search--menu').length === 0) {
        $.spinner().start();
        $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent($(scope).val()),
            method: 'GET',
            dataType: 'html',
            data: {
                filter: savedFilter,
            },
            success: processResponse,
            // complete: () => {
            //     toggleSuggestionFilter(true);
            // },
        });
    } else {
        toggleSuggestionsIcon('search');
        $(scope).siblings('.reset-button').removeClass('d-sm-block');
        clearModals();
        const headerHeight = $('.page-header').innerHeight() + $('.pre-header').innerHeight();
        const formHeight = $('.js-form').innerHeight() + headerHeight;
        if (!window.breakpoints.atLeast('md') || (formHeight >= window.innerHeight && window.breakpoints.atLeast('md'))) {
            $('.js-form').css('height', 'auto');
        }
    }
}

function saveSelectedValue(event) {
    $('.searchbar-open').data('saved-filter', event.target.value);
}

window.eventRadioFilter = (e) => {
    saveSelectedValue(e);
    getSuggestions($(e.currentTarget).closest('form').find('input.search-field'));
    toggleSuggestionFilter();
    toggleSuggestionSearchManual(false);
};


function checkValue(scope) {
    const scopeValue = $(scope).val();
    const isMdOrHigher = window.breakpoints.atLeast('md');

    if (scopeValue && scopeValue.length) {
        if (isMdOrHigher) {
            $clearButtonParent.removeClass('d-none');
            $('.input-group-divider').removeClass('d-none');
        } else {
            $resetButtonParent.addClass('d-none');
            $clearButtonParent.removeClass('d-none');
        }
    } else {
        $resetButtonParent.removeClass('d-none');
        $('.input-group-divider').addClass('d-none');
        $clearButtonParent.addClass('d-none');
        const endpoint = $('.suggestions-wrapper').data('clear');

        $.ajax({
            url: endpoint,
            method: 'GET',
            dataType: 'html',
            success: processResponse,
            complete: () => {
                toggleSuggestionFilter(true);
                toggleSuggestionSearchManual(true);
            },
        });
    }
}
export default class Search extends CommonSearch {

    init() {
        $('form[name="simpleSearch"]').submit((e) => {
            const suggestionsList = $('.suggestions .item');
            if (suggestionsList.filter('.selected').length !== 0) {
                e.preventDefault();
                suggestionsList.filter('.selected').find('a')[0].click();
            }
        });

        this.search();

        $('body').on('click', (e) => {
            if (!$('.suggestions').has(e.target).length && !$(e.target).hasClass('search-field')) {
                $('.suggestions').hide();
            }
        });

        $('body').on('click touchend', '.search-mobile button.fa-close', (e) => {
            e.preventDefault();
            $('.suggestions').hide();
            toggleSuggestionsIcon('search');
            tearDownSuggestions();
        });

        $('.site-search .reset-button').on('click', function () {
            $(this).removeClass('d-sm-block');
            $resetButtonParent.removeClass('d-none');
        });

        $('.site-search .clear-button').on('click', () => {
            $('.search-field').val('');

            const headerHeight = $('.page-header').innerHeight() + $('.pre-header').innerHeight();
            const formHeight = $('.js-form').innerHeight() + headerHeight;
            if (!window.breakpoints.atLeast('md') || (formHeight >= window.innerHeight && window.breakpoints.atLeast('md'))) {
                $('.js-form').css('height', 'auto');
            }
            if (window.breakpoints.atLeast('sm')) {
                $('.input-group-divider').removeClass('d-none');
            }
            $clearButtonParent.addClass('d-none');
            $('.input-group-divider').addClass('d-none');
            $resetButtonParent.removeClass('d-none');
            const endpoint = $('.suggestions-wrapper').data('clear');

            $.ajax({
                url: endpoint,
                method: 'GET',
                dataType: 'html',
                success: processResponse,
                complete: () => {
                    toggleSuggestionFilter(true);
                    toggleSuggestionSearchManual(true);
                },
            });
        });

        toggleSuggestionFilter();
        toggleSuggestionSearchManual(true);
    }

    search() {
        const UP_KEY = 38;
        const DOWN_KEY = 40;
        const DIRECTION_DOWN = 1;
        const DIRECTION_UP = -1;

        $('input.search-field').each(function () {
            /**
             * Use debounce to avoid making an Ajax call on every single key press by waiting a few
             * hundred milliseconds before making the request. Without debounce, the user sees the
             * browser blink with every key press.
             */
            const debounceSuggestions = debounce(getSuggestions, 600);
            $(this).on('input', function (e) {
                debounceSuggestions(this, e);
                checkValue(this);
            });

            $(this).on('keyup', (e) => {
                // Capture Down/Up Arrow Key Events
                switch (e.which) {
                case DOWN_KEY:
                    handleArrow(DIRECTION_DOWN);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                case UP_KEY:
                    handleArrow(DIRECTION_UP);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                default: break;
                }
            });
        });

        $('.search-options_radio').on('change', (e) => {
            window.eventRadioFilter(e);
            const $searchOption = $('.search-options_radio input:checked');
            const gtmData = $('.suggestions__product-recommendations').find(`[data-filter='${$searchOption.val()}']`).find('.recommendations').data('gtm');
            window.gtmSuggestionsRecommPromotions = gtmData && gtmData.gtmPromotionsArray ? JSON.parse(gtmData.gtmPromotionsArray) : null;
            window.gtmSuggestionsRecommProducts = gtmData && gtmData.gtmProductsArray ? JSON.parse(gtmData.gtmProductsArray) : null;
            onProductsImpression([gtmData && gtmData.gtmProductsArray ? JSON.parse(gtmData.gtmProductsArray) : []], window.currency);
            onPromosImpression([gtmData && gtmData.gtmPromotionsArray ? JSON.parse(gtmData.gtmPromotionsArray) : []]);
        });
    }
}
