import { uniq } from 'lodash';

export default class FavouriteStore {
    constructor() {
        this.country = null;
        this.city = null;
        this.stores = [];
    }

    init() {
        const $store = $('[name$="customer_store"]');
        const $country = $('[name$="customer_store__country"]');
        const $city = $('[name$="customer_store__city"]');

        if (!$store.length || !$country.length || !$city.length) {
            return;
        }

        this.changeSelectedStore();

        this.$cityDefaultOption = $city.find('option').first().clone();
        this.$storeDefaultOption = $store.find('option').first().clone();

        const url = $country.data('action');

        $country.on('change', (e) => {
            const countryCode = $(e.currentTarget).val();
            this.country = countryCode;

            $.spinner().start();

            $.ajax({
                type: 'get',
                url,
                data: { countryCode },
                success: (res) => {
                    let cities = [];

                    if (res.success && 'stores' in res && res.stores.length) {
                        this.stores = res.stores;
                        cities = res.stores.map((store) => store.city);
                    }

                    this.updateCitiesSelect(cities, $city);
                    this.resetStoresSelect($store);
                },
                error: (err) => {
                    console.warn(err);
                },
                complete: () => {
                    $.spinner().stop();
                },
            });
        });

        $city.on('change', (e) => {
            if (!this.country || !this.stores.length) {
                return;
            }

            this.city = $(e.currentTarget).val();

            const storesForCity = this.stores.filter((store) => store.city === this.city);

            this.updateStoresSelect(storesForCity, $store);
        });
    }

    updateCitiesSelect(cities, $city) {
        const uniqueCities = uniq(cities).sort();
        const $options = uniqueCities.map((city) => $('<option>').text(city));
        $city.html(this.$cityDefaultOption).append($options);
    }

    updateStoresSelect(stores, $store) {
        const $options = stores.map((store) => $('<option>').val(store.id).text(`${store.name} (${store.address})`));
        $store.html($options);
    }

    resetStoresSelect($store) {
        $store.html(this.$storeDefaultOption);
    }

    changeSelectedStore() {
        $('body').on('click', '.registration-form-store__change', () => {
            $('.registration-form-store__current').addClass('d-none').hide();
            $('.registration-form-store__inputs').removeClass('d-none').show();
        });
    }
}
