import Cookie from 'common/util/Cookie';
import { debounce } from 'lodash';
import { onProductsImpression, onProductClick, onPromosImpression, onPromoClick, onResetPlpFilter, onApplyPlpFilter } from '../seo/gtmEvents';

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
export function updateDom($results, selector) {
    const $updates = $results.find(selector);
    $(document).trigger('search:updatedom', $updates);
    $(selector).empty().html($updates.html());
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
export function handleRefinements($results) {
    $('.refinement.active').each(function () {
        $(this).removeClass('active');
        const activeDiv = $results.find(`.${$(this)[0].className.replace(/ /g, '.')}`);
        activeDiv.addClass('active');
        activeDiv.find('button.title').attr('aria-expanded', 'true');
    });

    updateDom($results, '.refinements');
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
export function parseResults(response) {
    const $results = $(response);
    const specialHandlers = {
        '.refinements': handleRefinements,
    };

    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.header-bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        '.grid-toggler',
        '.filter-bar',
        '.special-refinements',
        '.search-results__count',
        '.search-results__filter-reset',
    ].forEach((selector) => {
        updateDom($results, selector);
    });

    Object.keys(specialHandlers).forEach((selector) => {
        specialHandlers[selector]($results);
    });
}

/**
 * This function retrieves another page of content to display in the content search grid
 * @param {JQuery} $element - the jquery element that has the click event attached
 * @param {JQuery} $target - the jquery element that will receive the response
 * @return {undefined}
 */
export function getContent($element, $target) {
    const showMoreUrl = $element.data('url');
    $.spinner().start();
    $.ajax({
        url: showMoreUrl,
        method: 'GET',
        success(response) {
            $target.append(response);
            $.spinner().stop();
        },
        error() {
            $.spinner().stop();
        },
    });
}

/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
export function updateSortOptions(response) {
    const $tempDom = $('<div>').append($(response));
    const sortOptions = $tempDom.find('.grid-footer').data('sort-options').options;
    sortOptions.forEach((option) => {
        $(`option.${option.id}`).val(option.url);
    });
}

/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} productUrl - Ajax response HTML code
 * @param {Object} productsPlpArray - Ajax response HTML code
 * @return {Object} Return product object
 */
export function findProductInArrayObjects(productUrl, productsPlpArray) {
    let found = false;
    let arrLength = 0;
    let product = {};

    while (arrLength < productsPlpArray.length && !found) {
        if (productUrl.indexOf(productsPlpArray[arrLength].item_id) > -1) {
            product = productsPlpArray[arrLength];
            found = true;
        }
        arrLength++;
    }

    return product;
}

export default class Search {
    constructor() {
        this.$resultsHeader = $('.search-results__header');
        this.compactClass = 'toggleable-row--compact';
        this.loadingMore = false;
    }

    init() {
        this.handleUpdateDomEvent();
        this.filter();
        this.specialRefinements();
        this.closeRefinements();
        // this.resize();
        this.sort();
        this.showMore();
        this.infiniteScroll();
        this.applyFilter();
        this.showContentTab();
        this.toggleGrid();
        this.firstPageImpression();
        this.onClickProduct();
    }

    firstPageImpression() {
        onProductsImpression(window.plpProducts, window.currency);
        onPromosImpression(window.plpPromotions);
    }

    onClickProduct() {
        // Handle productClick
        $('body').on('click', '.pdp-link__anchor', (e) => {
            e.stopPropagation();

            const currentProductUrl = e.target.href || e.target.parentNode.href;
            const productObject = findProductInArrayObjects(currentProductUrl, window.plpProducts[0]);
            const promoObject = findProductInArrayObjects(currentProductUrl, window.plpPromotions[0]);
            if (Object.keys(promoObject).length !== 0) {
                onPromoClick([promoObject]);
            }
            onProductClick([productObject]);
        });
    }

    handleUpdateDomEvent() {
        $(document).on('search:updatedom', (e, object) => {
            const $updates = $(object);
            if ($updates && $updates.find('.refinements-wrapper').length > 0) {
                $updates.find('.refinements-wrapper').addClass('entering show');
            }
        });
    }

    filter() {
        // Display refinements bar when Menu icon clicked
        $('.search-results').on('click', 'button.filter-results', () => {
            const $wrapper = $('.refinements-wrapper');

            $wrapper.addClass('entering');
            $(document).trigger('search:filtersPanel', { opened: true });

            setTimeout(() => {
                $wrapper.addClass('show').attr('aria-hidden', false);
                $wrapper.find('.close').first().focus();
            }, 30);
        });
    }

    closeRefinements() {
        // Refinements close button
        $('.search-results').on('click', '.refinement-bar button.close, .refinements-wrapper button.close', (e) => {
            e.preventDefault();
            e.stopPropagation();
            const $wrapper = $('.refinements-wrapper');
            const $refinementBar = $('.refinement-bar');

            $wrapper.removeClass('show');
            $refinementBar.removeClass('child-selected');
            $(document).trigger('search:filtersPanel', { opened: false });

            setTimeout(() => {
                $wrapper.removeClass('entering').attr('aria-hidden', true);
                this.$resultsHeader.find('.button.filter-results').focus();
            }, 500);
        });
    }

    fadeGrid($grid) {
        return new Promise((resolve) => {
            $grid.addClass('fadeOut');

            setTimeout(() => {
                resolve($grid);
            }, 330);
        }).catch((err) => {
            console.warn('Error while fading grid', err);
            $grid.removeClass('fadeOut');
        });
    }

    toggleGrid() {
        $('body').on('click', '.grid-toggler--wide', () => {
            this.switchGrid(false);
        }).on('click', '.grid-toggler--compact', () => {
            this.switchGrid(true);
        });
    }

    switchGrid(compact = true) {
        const $grid = $('.search-results .toggleable-row');
        const $toggler = $('.search-results .grid-toggler');

        this.fadeGrid($grid)
            .then(() => {
                $grid.toggleClass(this.compactClass, compact).removeClass('fadeOut');
                $toggler.toggleClass('compact', compact);
                $toggler.trigger({
                    type: 'onswitch:grid',
                    compact,
                });
            });
    }

    refreshGridToggler() {
        const $grid = $('.search-results .toggleable-row');
        const $toggler = $('.search-results .grid-toggler');

        if ($grid.hasClass(this.compactClass)) {
            $toggler.addClass('compact');
        } else {
            $toggler.removeClass('compact');
        }
    }

    specialRefinements() {
        const $special = $('.special-refinement');
        const delta = 100;
        let animating = false;

        if (!$special.length) {
            return;
        }

        $('body').on('click', '.special-refinement__arrow', (e) => {
            if (animating) {
                return;
            }

            animating = true;

            const $scroller = $('.special-refinement__values');
            const action = $(e.currentTarget).data('action');
            let dir = '+';

            if (action === 'prev') {
                dir = '-';
            }

            $scroller.stop().animate({ scrollLeft: `${dir}=${delta}` }, {
                duration: 350,
                complete: () => {
                    animating = false;
                },
            });
        });
    }

    sort() {
        // Handle sort order menu selection
        $('.search-results').on('change', '[name=sort-order]', function (e) {
            e.preventDefault();

            $.spinner().start();
            $(this).trigger('search:sort', this.value);
            $.ajax({
                url: this.value,
                data: { selectedUrl: this.value },
                method: 'GET',
                success(response) {
                    $('.product-grid').empty().html(response);
                    onProductsImpression(window.plpProducts, window.currency);
                    onPromosImpression(window.plpPromotions);
                    $.spinner().stop();
                },
                error() {
                    $.spinner().stop();
                },
            });
        });
    }

    showMore() {
        const self = this;
        // Show more products
        const showMoreSelector = '.search-results .show-more button';
        let showMoreCookie = {};

        $(document).on('search:loadMore', (e) => {
            if (self.loadingMore) {
                return;
            }

            self.loadingMore = true;
            const showMoreUrl = $(showMoreSelector).data('url');
            const urlParams = showMoreUrl.split('&');
            let showMoreObject = {};
            let start = null;
            let sz = null;
            for (let i = 0; i < urlParams.length; i++) {
                const p = urlParams[i].split('=');
                if (p[0] === 'start') {
                    start = p[1];
                }
                if (p[0] === 'sz') {
                    sz = p[1];
                }
                if (start != null && sz != null) {
                    if (Cookie.getValue('showMore') != null) {
                        showMoreObject = JSON.parse(Cookie.getValue('showMore'));
                    }
                    showMoreObject.count = start / sz;
                    Cookie.create('showMore', JSON.stringify(showMoreObject), 1);
                }
            }
            // $.spinner().start();
            $(showMoreSelector).prop('disabled', true);
            $(showMoreSelector).trigger('search:showMore', e);
            $.ajax({
                url: showMoreUrl,
                data: { selectedUrl: showMoreUrl },
                method: 'GET',
                success(response) {
                    $('.grid-footer').replaceWith(response);
                    updateSortOptions(response);
                    self.refreshGridToggler();
                    onProductsImpression(window.plpProducts, window.currency);
                    onPromosImpression(window.plpPromotions);
                },
                complete() {
                    $.spinner().stop();
                    $(showMoreSelector).prop('disabled', false);
                    self.loadingMore = false;
                    if (showMoreCookie && showMoreCookie.count && showMoreCookie.count > 0) {
                        showMoreCookie.count += -1;
                        $(document).trigger('search:loadMore');
                    } else {
                        $(window).scrollTop(showMoreCookie.position);
                        showMoreCookie = {};
                    }
                },
            });
        });

        $('.search-results').on('click', '.show-more button', (e) => {
            e.stopPropagation();
            e.preventDefault();
            $(document).trigger('search:loadMore');
        });

        if (Cookie.getValue('showMore') != null) {
            if (window.performance && (window.performance.navigation.type === window.performance.navigation.TYPE_BACK_FORWARD || (window.performance.getEntriesByType('navigation')[0].type === 'back_forward'))) {
                showMoreCookie = JSON.parse(Cookie.getValue('showMore'));
                showMoreCookie.count += -1;
                $(document).trigger('search:loadMore');
            } else {
                const showMoreObject = {};
                showMoreObject.count = 0;
                Cookie.create('showMore', JSON.stringify(showMoreObject), 1);
            }
        }
    }

    infiniteScroll() {
        // eslint-disable-next-line eqeqeq
        if ($('.grid-footer').data('infinite-scroll') != true) {
            return;
        }

        $(window).on('scroll', debounce(() => {
            if (Cookie.getValue('showMore') != null) {
                const showMoreObject = JSON.parse(Cookie.getValue('showMore'));
                showMoreObject.position = $(window).scrollTop();
                Cookie.create('showMore', JSON.stringify(showMoreObject), 1);
            }
            if (!$('.grid-footer .show-more').isInViewport({ topOffset: -30 })) {
                return;
            }

            $(document).trigger('search:loadMore');
        }, 250));
    }

    applyFilter() {
        const self = this;

        function createFiltersObject() {
            const prefVals = $('.refinements input[data-checked="true"]').toArray().map(t => $(t).data('refinementValue'));
            const prefKeys = $('.refinements input[data-checked="true"]').toArray().map(t => $(t).data('refinementId'));
            const filters = {};

            prefKeys.forEach((prefKey, index) => {
                if (!filters[prefKey]) {
                    filters[prefKey] = [];
                }

                const prefVal = prefVals[index];

                if (!filters[prefKey].includes(prefVal)) {
                    filters[prefKey].push(prefVal);
                }
            });

            return filters;
        }
        // Handle refinement value selection and reset click
        function handleApplyFilter(e) {
            e.preventDefault();
            e.stopPropagation();

            $.spinner().start();
            $(this).trigger('search:filter', e);
            $.ajax({
                url: $(this).data('href'),
                data: {
                    page: $('.grid-footer').data('page-number'),
                    selectedUrl: $(this).data('href'),
                },
                method: 'GET',
                success(response) {
                    parseResults(response);
                    self.refreshGridToggler();
                    const filters = createFiltersObject();
                    if (Object.keys(filters).length === 0) {
                        onResetPlpFilter();
                    } else {
                        onApplyPlpFilter(filters);
                    }
                    onProductsImpression(window.plpProducts, window.currency);
                    onPromosImpression(window.plpPromotions);
                },
                complete() {
                    $.spinner().stop();
                },
            });
        }

        $('.search-results')
            .on('click', '.refinements li button, .refinement-bar button.reset, .search-results__header .reset, .filter-value button, .swatch-filter button, apply', handleApplyFilter)
            .on('change', '.refinements li input:not([name="sort-order"])', handleApplyFilter)
            .on('change', '.special-refinements li input', handleApplyFilter);
    }

    showContentTab() {
        // Display content results from the search
        $('.search-results').on('click', '.content-search', function () {
            if ($('#content-search-results').html() === '') {
                getContent($(this), $('#content-search-results'));
            }
        });

        // Display the next page of content results from the search
        $('.search-results').on('click', '.show-more-content button', function () {
            getContent($(this), $('#content-search-results'));
            $('.show-more-content').remove();
        });
    }
}
