import MiniWishlist from 'common/components/miniWishlist';
import WishlistSizeSelector from './wishlistSizeSelector';

export function updateCounter(count) {
    const $target = $('.wishlist-quantity.counter');

    if (!$target.length) {
        return;
    }

    $target.text(count).toggle(count > 0);
}
export default class extends MiniWishlist {


    events() {
        // Event not on body, navbar stops propagation
        $('.page-header .nav').on('click', '.wishlist-open', (e) => {
            e.preventDefault();
            this.open();
        });

        $(document).on('wishlist:afterAddToList', () => {
            this.miniwishlist.setLoading();
            // this.open(true);
            this.updateContent();
        });

        $('body')
            .on('click', '.wishlist-close', () => {
                this.miniwishlist.close();
            })
            .on('click', '.remove-from-miniwishlist', (e) => {
                e.preventDefault();

                const actionUrl = $(e.currentTarget).data('action');
                const productID = $(e.currentTarget).data('pid');
                const productName = $(e.currentTarget).data('name');
                const uuid = $(e.currentTarget).data('uuid');
                this.confirmDelete(actionUrl, productID, productName, uuid);
            })
            .on('click', '.wishlist-delete-confirmation-btn', this.remove.bind(this))
            .on('wishlist:update', this.updateContent.bind(this));
        $(document).on('click', '.card--wishlist a, .panel--wishlist a', (e) => {
            if ($(e.currentTarget).closest('.account-wishlist').length === 0) {
                this.onClickProduct(e);
            }
        });
    }
    updateContent() {
        const self = this;

        $.ajax({
            url: this.url,
            type: 'get',
            dataType: 'html',
            success(response) {
                self.update = false;
                self.miniwishlist.setContent(response);
                const count = $(response).find('.wishlist-quantity').data('quantity');
                updateCounter(parseInt(count, 10));
                self.onWishlistShow();
                // wishlist size selector
                const wishlistSizeSelector = new WishlistSizeSelector();
                wishlistSizeSelector.init();
            },
            error(err) {
                self.miniwishlist.close();
                console.warn(err);
            },
        });
    }
}
