/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
export function validateForm(event) {
    let valid = true;
    if (this.checkValidity && !this.checkValidity()) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        $(this).find('input, select, textarea').each(function () {
            if (!this.validity.valid) {
                $(this).trigger('invalid', this.validity);
            }
        });
    }
    return valid;
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
export function clearForm(form) {
    $(form).find('.form-control.is-invalid, .form-group.is-invalid, .custom-select.is-invalid').removeClass('is-invalid');
}

export default class Validation {
    /**
     * @param {jQuery|string} [context] A context for the jQuery selection of the form elements
     * @return {undefined}
     */
    constructor(context) {
        this.context = context;

        this.functions = {
            validateForm(form, event) {
                validateForm.call($(form), event || null);
            },
            clearForm,
        };
    }

    init() {
        this.invalid();
        this.submit();
        this.buttonClick();
    }

    invalid() {
        const self = this;

        $('form input, form select, form textarea', this.context).on('invalid', function (e) {
            e.preventDefault();
            if ($(this).data('validator') !== 'custom') {
                this.setCustomValidity('');
            }

            if (!self.firstInvalid) {
                self.firstInvalid = this;
            }

            if (!this.validity.valid) {
                let validationMessage = this.validationMessage;
                $(this).addClass('is-invalid');
                $(this).parents('.form-group').addClass('is-invalid');
                if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                    validationMessage = $(this).data('pattern-mismatch');
                }
                if ((this.validity.rangeOverflow || this.validity.rangeUnderflow)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if ((this.validity.tooLong || this.validity.tooShort)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if (this.validity.valueMissing && $(this).data('missing-error')) {
                    validationMessage = $(this).data('missing-error');
                }
                $(this).parents('.form-group').find('.invalid-feedback')
                    .text(validationMessage);
            }

            // Scroll to first error and reset
            if (self.firstInvalid) {
                const offset = $(self.firstInvalid).offset().top - $('.page-header > nav').outerHeight() - 30;

                $('html, body').stop().animate({
                    scrollTop: offset,
                }, 500);

                setTimeout(() => {
                    self.firstInvalid = null;
                }, 300);
            }
        });
    }

    submit() {
        $('form', this.context).on('submit', function (e) {
            return validateForm.call(this, e);
        });
    }

    buttonClick() {
        $('form button[type="submit"], form input[type="submit"]', this.context).on('click', function () {
            // clear all errors when trying to submit the form
            clearForm($(this).parents('form'));
            if ($('body').find('.return-controls, .return-items').length > 0) {
                new Validation().invalid();
            }
        });
    }
}
