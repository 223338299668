export default class RetailTuneWidget {
    init() {
        if (window.retailTuneProducts) {
            const RtLang = window.retailTuneProducts.lang;
            const RtDevice = window.retailTuneProducts.device;
            const RtSession = window.retailTuneProducts.session;
            const RtProductCode = window.retailTuneProducts.id;
            const RtProductTitle = window.retailTuneProducts.name;
            const RtProductPrice = window.retailTuneProducts.price;
            const RtProductDiscountedPrice = window.retailTuneProducts.salesPrice;
            const RtProductimage = window.retailTuneProducts.image;
            const RtProductUrl = window.location.href;
            const RtStoreid = '';
            const RtProductType = '1';
            const Active = '1';
            // eslint-disable-next-line
            retailtune_main(RtLang, RtDevice, RtSession, RtProductCode, RtProductTitle, RtProductPrice, RtProductDiscountedPrice, RtProductimage, RtProductUrl, RtStoreid, RtProductType, Active);
        }
    }
}
