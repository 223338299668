import Resource from 'common/util/Resource';
import Rt from 'common/product/retailTuneWidget';
import focusHelper from '../components/focus';
import { onAddToCart } from '../seo/gtmEvents';

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
export function getPidValue($el) {
    let pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text();
    } else {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }

    return pid;
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
export function getQuantitySelector($el) {
    return $el && $('.set-items').length
        ? $($el).closest('.product-detail').find('.quantity-select')
        : $('.quantity-select');
}

/**
 * Retrieves the value associated with the Quantity pull-down menu or 1
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
export function getQuantitySelected($el) {
    return getQuantitySelector($el).val() || 1;
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
export function processSwatchValues(attr, $productContainer, msgs) {
    attr.values.forEach((attrValue) => {
        const $attrValue = $productContainer.find(`[data-attr="${attr.id}"] [data-attr-value="${
            attrValue.value}"]`);
        const $swatchButton = $attrValue.parent();

        if (attrValue.selected) {
            $swatchButton.addClass('attribute__value--selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
        } else {
            $swatchButton.removeClass('attribute__value--selected');
            $attrValue.siblings('.selected-assistive-text').empty();
        }

        if (attrValue.url) {
            $swatchButton.attr('data-url', attrValue.url);
        } else {
            $swatchButton.removeAttr('data-url');
        }

        // Disable if not selectable
        $swatchButton.toggleClass('attribute__value--selectable', attrValue.selectable);
        $swatchButton.toggleClass('attribute__value--unselectable disabled', !attrValue.selectable);

        // do not set disabled prop, as to allow for back-in-stock request
        // $swatchButton.prop('disabled', !attrValue.selectable);

        // Hide swatch if set to "isExcludedAttribute"
        $swatchButton.toggleClass('attribute__value--hidden', attrValue.isExcludedAttribute);
    });
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
export function processNonSwatchValues(attr, $productContainer) {
    const $attr = `[data-attr="${attr.id}"]`;
    const $defaultOption = $productContainer.find(`${$attr} .select-${attr.id} option:first`);
    $defaultOption.attr('value', attr.resetUrl);

    attr.values.forEach((attrValue) => {
        const $attrValue = $productContainer
            .find(`${$attr} [data-attr-value="${attrValue.value}"]`);
        $attrValue.attr('value', attrValue.url)
            .removeAttr('disabled');

        if (!attrValue.selectable) {
            $attrValue.attr('disabled', true);
        }
    });
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
export function updateAttrs(attrs, $productContainer, msgs) {
    // Currently, the only attribute type that has image swatches is Color.
    const attrsWithSwatches = ['color', 'size'];

    attrs.forEach((attr) => {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer, msgs);
        } else {
            processNonSwatchValues(attr, $productContainer);
        }

        // If color, update label with name of the selected value
        if (attr.id === 'color') {
            const $target = $('.color.attribute__label');
            let label = '';
            const selected = attr.values.filter((value) => value.selected);
            const siteId = $('body').attr('data-site-id') || null;

            if (selected.length) {
                const attrType = Resource.msg('product.pdp.color', 'product', null);
                if (siteId !== 'colmar-row') {
                    label = `<span>${attrType}</span><span class="value">: ${selected[0].displayValue}</span>`;
                } else {
                    label = `<span class="value">${selected[0].displayValue}</span>`;
                }
            } else {
                label = attr.displayName;
            }

            $target.html(label);
        }
    });
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
export function updateAvailability(response, $productContainer) {
    let availabilityValue = '';
    const availabilityMessages = response.product.availability.messages;
    if (response.product.readyToOrder) {
        availabilityMessages.forEach((message) => {
            availabilityValue += `<div><span class="small text-gray-700">${message}</span></div>`;
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer,
        message: availabilityValue,
        resources: response.resources,
    });
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
export function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    let html = '';

    attributes.forEach((attributeGroup) => {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach((attribute) => {
                html += `<div class="attribute-values">${attribute.label}: ${
                    attribute.value}</div>`;
            });
        }
    });

    return html;
}

/**
 * @typedef UpdatedOptionValue
 * @type Object
 * @property {string} id - Option value ID for look up
 * @property {string} url - Updated option value selection URL
 */

/**
 * @typedef OptionSelectionResponse
 * @type Object
 * @property {string} priceHtml - Updated price HTML code
 * @property {Object} options - Updated Options
 * @property {string} options.id - Option ID
 * @property {UpdatedOptionValue[]} options.values - Option values
 */

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} optionsHtml - Ajax response optionsHtml from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
export function updateOptions(optionsHtml, $productContainer) {
    // Update options
    $productContainer.find('.product-options').empty().html(optionsHtml);
}

export function imageTemplate(image, index) {
    return `
        <div class="primary-images__image col col-12 ${index < 4 ? ' col-lg-6' : 'col-lg-4'} p-0 m-0 flex-grow-1">
            <div class="image">
                <div class="container-lazyload">
                    <a href="#" class="product-image__zoom-trigger" data-product-image-index="${index}">
                        <img data-srcset="${image.url} 1x,
                                            ${image.hd} 1.5x,
                                            ${image.qhd} 2x"
                            data-src="${image.hd}"
                            src="${image.placeholder}"
                            class="lazyload d-block img-fluid product-image"
                            alt="${image.alt} image number ${image.index}"
                            itemprop="image" />
                    </a>
                </div>
            </div>
        </div>`;
}

export function videoTemplate(videoUrl) {
    if (videoUrl) {
        return `
            <div class="video-wrapper primary-images__image col col-12 col-lg-4 p-0 m-0 flex-grow-1">
                <div class="image video-component__container">
                    <div class="container-lazyload">
                        <video class="video-cover aos-init is-playing" muted loop autoplay="autoplay" title="" controlslist="nodownload"
                            playsinline webkit-playsinline preload="none" src="${videoUrl}" data-enable-audio-only-with-button="true">
                        </video>
                        <button class="experience-video__controls__play position-absolute" aria-label="play pause" type="button">
                            <i class="isicon isicon--video-play" style="width: 20px; height: 20px; line-height: 20px;">
                            </i>
                            <i class="isicon isicon--video-pause" style="width: 20px; height: 20px; line-height: 20px;">                      
                            </i>
                            <span class="sr-only">play pause</span>
                        </button>
                    </div>
                </div>
            </div>`;
    }
    return '';
}

export function updateVideo($productContainer, videoUrl) {
    // Find existing video elements and replace them with the updated template
    const $existingVideos = $productContainer.find('.video-cover');
    const imageContainer = $productContainer.find('.primary-images__container');
    const videoWrapper = $productContainer.find('.video-wrapper');
    if (videoUrl) {
        if ($existingVideos.length > 0) {
            videoWrapper.remove();
            imageContainer.append(videoTemplate(videoUrl));
        } else {
        // If no existing video elements, simply append the new template
            imageContainer.append(videoTemplate(videoUrl));
        }
        // trigger videoUpdate in video.js
        document.dispatchEvent(new Event('videoUpdated'));
    } else {
        videoWrapper.remove();
    }
}

export function updateImages(images, detailImages, $productContainer, productVideoUrl, videoPDPEnable) {
    const $currentImages = $productContainer.find('[data-product-image-index]');
    const currentImagesLength = $currentImages.length - 1; // first one is always duplicated for mobile
    const nextImagesLength = images.length;

    $currentImages.find('img').removeClass('lazyloaded').addClass('lazyloading');

    images.forEach((img, index) => {
        const $target = $productContainer.find(`[data-product-image-index="${index}"]`);
        if (!$target.length) {
            return;
        }

        $target.find('img').each((i, el) => {
            let srcset = `${img.url} 1x, ${img.hd} 1.5x, ${img.qhd} 2x`;
            if (!$(el).hasClass('detail')) {
                $(el).addClass('lazyload').attr('data-srcset', srcset);
            } else {
                srcset = detailImages != null ? `${detailImages[0].url} 1x, ${detailImages[0].hd} 1.5x, ${detailImages[0].qhd} 2x` : srcset;
                $(el).addClass('lazyload').attr('data-srcset', srcset);
            }
        });
    });

    if (nextImagesLength === currentImagesLength && !videoPDPEnable) {
        return;
    }

    // Handle different number of images
    for (let i = (videoPDPEnable ? 0 : nextImagesLength); i < currentImagesLength; i++) {
        // Remove extra images
        $productContainer.find(`[data-product-image-index="${i}"]`).closest('.primary-images__image').remove();
    }

    for (let i = (videoPDPEnable ? 0 : currentImagesLength); i < nextImagesLength; i++) {
        const element = images[i];
        let img;
        if (videoPDPEnable && productVideoUrl && images.length === 7 && i === 6) {
            updateVideo($productContainer, productVideoUrl);
        } else {
            img = imageTemplate(element, i);
        }

        // Add missing images
        $productContainer.find('.primary-images__container--loop').append($(img));
    }
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
export function handleVariantResponse(response, $productContainer, sizeModalClicked) {
    const isChoiceOfBonusProducts =
        $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    let isVaraint;
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
        isVaraint = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVaraint) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update primary images
    const primaryImageUrls = response.product.images.large;
    const detailImageUrls = response.product.images.detail ? response.product.images.detail : null;
    const productVideoUrl = response.product.productVideoUrl ? response.product.productVideoUrl : null;
    const videoPDPEnable = response.product.videoPDPEnable;
    updateImages(primaryImageUrls, detailImageUrls, $productContainer, productVideoUrl, videoPDPEnable);
    // Update Video
    if (videoPDPEnable && primaryImageUrls.length < 7) {
        updateVideo($productContainer, productVideoUrl);
    }
    $(document).trigger('product:zoomUpdate', { images: response.product.zoom });

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        let $prices;

        if ($('.prices .price', $productContainer).length) {
            $prices = $('.prices .price', $productContainer);
        } else if ($('.prices .price', $productContainer.parent()).length) {
            // Handle non-modal quickview
            $prices = $('.prices .price', $productContainer.parent());
        } else {
            $prices = $('.prices .price');
        }

        $prices.replaceWith(response.product.price.html);

        // eslint-disable-next-line no-undef
        if (typeof Klarna !== 'undefined' && Klarna.OnsiteMessaging) {
            // eslint-disable-next-line no-undef
            Klarna.OnsiteMessaging.refresh();
        }
    }

    // Update promotions
    $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);

    updateAvailability(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        const $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer,
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, isPriceInError: response.isPriceInError, $productContainer, sizeModalClicked,
        }).trigger('product:statusUpdate', [response.product, $productContainer]);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty()
        .html(getAttributesHtml(response.product.attributes));

    // update retailTuneProducts
    if (response.retailTuneProducts != null) {
        const rt = new Rt();
        window.retailTuneProducts = response.retailTuneProducts;
        rt.init();
    }

    // SUP-429 -> handle isPromoLabel attribute, different for single color
    if (response.product.isPromoLabel) {
        $('.header-details .image-badge__item_red').show();
    } else {
        $('.header-details .image-badge__item_red').hide();
    }

    // Update Virtual Try on and oobexr attribute
    if (response.product && response.vtoProductEnabled) {
        $productContainer.find('.main details .vto-container button.vto-container_button').data('barcode', response.product.barcode);
    }
    if (response.product && response.virtualOobexrProductEnabled && response.virtualOobexrProductEnabled.ID) {
        $productContainer.find('.main-details .vto-oobexr-container button.vto-oobexr-container_button').data('sku', response.virtualOobexrProductEnabled.ID);
    }

    if (response.product.readyToOrder && response.product.available && !response.isPriceInError) {
        // Trigger addToCart
        if (sizeModalClicked) {
            $('.add-to-cart.btn.btn-primary:not(.w-100)').trigger('click');
        }
    }
}

// /**
//  * Parses JSON from Ajax call made whenever an attribute value is [de]selected
//  * @param {Object} response - response from Ajax call
//  */
// export function populateVariantAttributes(response) {
//     // fill messages & input fields
//     $('.product-detail').find('.product-variant').text(response.variantDescription);
//     $('.product-detail').find('input[name=actionBis]').val(response.modalSubmitUrl);
//     $('.product-detail').find('input[name=skuBis]').val(response.sku);
//     $('.product-detail').find('input[name=emailBis]').val(response.suggestedEmail);
// }

/**
 * @typespec UpdatedQuantity
 * @type Object
 * @property {boolean} selected - Whether the quantity has been selected
 * @property {string} value - The number of products to purchase
 * @property {string} url - Compiled URL that specifies variation attributes, product ID, options,
 *     etc.
 */

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
export function updateQuantities(quantities, $productContainer) {
    if ($productContainer.parent('.bonus-product-item').length <= 0) {
        const optionsHtml = quantities.map((quantity) => {
            const selected = quantity.selected ? ' selected ' : '';
            return `<option value="${quantity.value}"  data-url="${quantity.url}"${
                selected}>${quantity.value}</option>`;
        }).join('');
        getQuantitySelector($productContainer).empty().html(optionsHtml);
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
export function attributeSelect(selectedValueUrl, $productContainer, sizeModalClicked) {
    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect',
            { url: selectedValueUrl, container: $productContainer });

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success(data) {
                // populateVariantAttributes(data);
                handleVariantResponse(data, $productContainer, sizeModalClicked);
                updateOptions(data.product.optionsHtml, $productContainer);
                updateQuantities(data.product.quantities, $productContainer);
                $('body').trigger('product:afterAttributeSelect',
                    { data, container: $productContainer });
            },
            complete() {
                $.spinner().stop();
            },
        });
    }
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
export function getAddToCartUrl(context) {
    let addToCartUrl = $('.add-to-cart-url', '.main-details').val();

    // Handle non-modal quickview
    if (!addToCartUrl) {
        addToCartUrl = $('.add-to-cart-url', '.quickview-col').val();
    }

    if (!addToCartUrl) {
        addToCartUrl = $('.add-to-cart-url', context || '.product-quickview').val();
    }

    return addToCartUrl;
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
export function parseHtml(html) {
    const $html = $('<div>').append($.parseHTML(html));

    const body = $html.find('.choice-of-bonus-product');
    const footer = $html.find('.modal-footer').children();

    return { body, footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
export function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    let bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    const htmlString = `${'<!-- Modal -->'
        + '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog choose-bonus-product-dialog" '
        + 'data-total-qty="'}${data.maxBonusItems}"`
        + `data-UUID="${data.uuid}"`
        + `data-pliUUID="${data.pliUUID}"`
        + `data-addToCartUrl="${data.addToCartUrl}"`
        + 'data-pageStart="0"'
        + `data-pageSize="${data.pageSize}"`
        + `data-moreURL="${data.showProductsUrlRuleBased}"`
        + `data-bonusChoiceRuleBased="${data.bonusChoiceRuleBased}">`
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + `    <span class="">${data.labels.selectprods}</span>`
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success(response) {
            const parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error() {
            $.spinner().stop();
        },
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
export function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
export function getChildProducts() {
    const childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10),
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
export function getOptions($productContainer) {
    const options = $productContainer
        .find('.product-option')
        .map(function () {
            const $elOption = $(this).find('.options-select');
            const urlValue = $elOption.val();
            const selectedValueId = $elOption.find(`option[value="${urlValue}"]`)
                .data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId,
            };
        }).toArray();

    return JSON.stringify(options);
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
export function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url,
            method: 'GET',
            success() {
                // reporting urls hit on the server
            },
            error() {
                // no reporting urls hit on the server
            },
        });
    }
}

export default class ProductBase {
    constructor() {
        this.attributeSelect = attributeSelect;
        this.getPidValue = getPidValue;
        this.getQuantitySelected = getQuantitySelected;
        this.miniCartReportingUrl = miniCartReportingUrl;
        this.methods = {
            editBonusProducts(data) {
                chooseBonusProducts(data);
            },
        };
    }

    focusChooseBonusProductModal() {
        $('body').on('shown.bs.modal', '#chooseBonusProductModal', () => {
            $('#chooseBonusProductModal').siblings().attr('aria-hidden', 'true');
            $('#chooseBonusProductModal .close').focus();
        });
    }

    onClosingChooseBonusProductModal() {
        $('body').on('hidden.bs.modal', '#chooseBonusProductModal', () => {
            $('#chooseBonusProductModal').siblings().attr('aria-hidden', 'false');
        });
    }

    trapChooseBonusProductModalFocus() {
        $('body').on('keydown', '#chooseBonusProductModal', (e) => {
            const focusParams = {
                event: e,
                containerSelector: '#chooseBonusProductModal',
                firstElementSelector: '.close',
                lastElementSelector: '.add-bonus-products',
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    }

    buttonAttributes() {
        $(document).on('click', '[data-attr="color"] button, [data-attr="size"] button', function (e) {
            e.preventDefault();

            if ($(this).attr('disabled')) {
                return;
            }
            let $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            const $sizeModalClicked = $(this).hasClass('size-modal-button') || false;
            if (!$(this).hasClass('attribute__value--selected')) {
                attributeSelect($(this).attr('data-url'), $productContainer, $sizeModalClicked);
            }
        });
    }

    availability() {
        $(document).on('change', '.quantity-select', function (e) {
            e.preventDefault();

            let $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.modal-content').find('.product-quickview');
            }

            if ($('.bundle-items', $productContainer).length === 0) {
                attributeSelect($(e.currentTarget).find('option:selected').data('url'),
                    $productContainer);
            }
        });
    }

    addToCart() {
        $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function () {
            let pidsObj;
            let setPids;

            $('body').trigger('product:beforeAddToCart', this);

            if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
                setPids = [];

                $('.product-detail').each(function () {
                    if (!$(this).hasClass('product-set-detail')) {
                        setPids.push({
                            pid: $(this).find('.product-id').text(),
                            qty: $(this).find('.quantity-select').val(),
                            options: getOptions($(this)),
                        });
                    }
                });
                pidsObj = JSON.stringify(setPids);
            }

            let pid = getPidValue($(this));
            if (!pid) {
                pid = $('.product-id').html();
            }

            let $productContainer = $(this).closest('.product-detail');

            if (!$productContainer.length) {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
            }

            // Handle non-modal quickview
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.quickview-col').find('.product-detail');
            }

            const addToCartUrl = getAddToCartUrl($productContainer);

            const form = {
                pid,
                pidsObj,
                childProducts: getChildProducts(),
                quantity: getQuantitySelected($(this)),
            };

            if (!$('.bundle-item').length) {
                form.options = getOptions($productContainer);
            }

            $(this).trigger('updateAddToCartFormData', form);

            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success(data) {
                        handlePostCartAdd(data);
                        $('body').trigger('product:afterAddToCart', data);
                        miniCartReportingUrl(data.reportingURL);

                        const productObject = data.pdpProducts
                                ? [...data.pdpProducts]
                                : [...window.pdpProducts];
                        // @todo give the possibility to add more quantity
                        if (productObject.length > 0 && !data.error) {
                            const foundItem = window.lineItemCtnrProducts[0].find(item => item.item_id === productObject[0].item_id);
                            productObject[0].quantity = form.quantity;
                            productObject[0].item_list_id = window.dataLayer[0].page_type;
                            productObject[0].item_list_name = window.dataLayer[0].site_section_3;
                            onAddToCart(window.currency, productObject);
                            if (foundItem) {
                                foundItem.quantity += form.quantity;
                            } else {
                                productObject[0].item_list_id = 'MiniCart';
                                productObject[0].item_list_name = 'MiniCart';
                                productObject[0].index = window.lineItemCtnrProducts[0].length;
                                window.lineItemCtnrProducts[0].push(productObject[0]);
                            }
                        }
                    },
                });
            }
        });
    }

    selectBonusProduct() {
        $(document).on('click', '.select-bonus-product', function () {
            const $choiceOfBonusProduct = $(this).parents('.choice-of-bonus-product');
            const pid = $(this).data('pid');
            const maxPids = $('.choose-bonus-product-dialog').data('total-qty');
            const submittedQty = parseInt($choiceOfBonusProduct.find('.bonus-quantity-select').val(), 10);
            let totalQty = 0;
            $.each($('#chooseBonusProductModal .selected-bonus-products .selected-pid'), function () {
                totalQty += $(this).data('qty');
            });
            totalQty += submittedQty;
            const optionID = $choiceOfBonusProduct.find('.product-option').data('option-id');
            const valueId = $choiceOfBonusProduct.find('.options-select option:selected').data('valueId');
            if (totalQty <= maxPids) {
                const selectedBonusProductHtml = `${''
                + '<div class="selected-pid row" '
                + 'data-pid="'}${pid}"`
                + `data-qty="${submittedQty}"`
                + `data-optionID="${optionID || ''}"`
                + `data-option-selected-value="${valueId || ''}"`
                + '>'
                + `<div class="col-sm-11 col-9 bonus-product-name" >${
                    $choiceOfBonusProduct.find('.product-name').html()
                }</div>`
                + '<div class="col-1"><i class="fa fa-times" aria-hidden="true"></i></div>'
                + '</div>'
                ;
                $('#chooseBonusProductModal .selected-bonus-products').append(selectedBonusProductHtml);
                $('.pre-cart-products').html(totalQty);
                $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
            } else {
                $('.selected-bonus-products .bonus-summary').addClass('alert-danger');
            }
        });
    }

    removeBonusProduct() {
        $(document).on('click', '.selected-pid', function () {
            $(this).remove();
            const $selected = $('#chooseBonusProductModal .selected-bonus-products .selected-pid');
            let count = 0;
            if ($selected.length) {
                $selected.each(function () {
                    count += parseInt($(this).data('qty'), 10);
                });
            }

            $('.pre-cart-products').html(count);
            $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
        });
    }

    enableBonusProductSelection() {
        $('body').on('bonusproduct:updateSelectButton', (e, response) => {
            $('button.select-bonus-product', response.$productContainer).attr('disabled',
                (!response.product.readyToOrder || !response.product.available));
            const pid = response.product.id;
            $('button.select-bonus-product', response.$productContainer).data('pid', pid);
        });
    }

    showMoreBonusProducts() {
        $(document).on('click', '.show-more-bonus-products', function () {
            const url = $(this).data('url');
            $('.modal-content').spinner().start();
            $.ajax({
                url,
                method: 'GET',
                success(html) {
                    const parsedHtml = parseHtml(html);
                    $('.modal-body').append(parsedHtml.body);
                    $('.show-more-bonus-products:first').remove();
                    $('.modal-content').spinner().stop();
                },
                error() {
                    $('.modal-content').spinner().stop();
                },
            });
        });
    }

    addBonusProductsToCart() {
        $(document).on('click', '.add-bonus-products', () => {
            const $readyToOrderBonusProducts = $('.choose-bonus-product-dialog .selected-pid');
            let queryString = '?pids=';
            const url = $('.choose-bonus-product-dialog').data('addtocarturl');
            const pidsObject = {
                bonusProducts: [],
            };

            $.each($readyToOrderBonusProducts, function () {
                const qtyOption =
                    parseInt($(this)
                        .data('qty'), 10);

                let option = null;
                if (qtyOption > 0) {
                    if ($(this).data('optionid') && $(this).data('option-selected-value')) {
                        option = {};
                        option.optionId = $(this).data('optionid');
                        option.productId = $(this).data('pid');
                        option.selectedValueId = $(this).data('option-selected-value');
                    }
                    pidsObject.bonusProducts.push({
                        pid: $(this).data('pid'),
                        qty: qtyOption,
                        options: [option],
                    });
                    pidsObject.totalQty = parseInt($('.pre-cart-products').html(), 10);
                }
            });
            queryString += JSON.stringify(pidsObject);
            queryString = `${queryString}&uuid=${$('.choose-bonus-product-dialog').data('uuid')}`;
            queryString = `${queryString}&pliuuid=${$('.choose-bonus-product-dialog').data('pliuuid')}`;
            $.spinner().start();
            $.ajax({
                url: url + queryString,
                method: 'POST',
                success(data) {
                    $.spinner().stop();
                    if (data.error) {
                        $('#chooseBonusProductModal').modal('hide');
                        if ($('.add-to-cart-messages').length === 0) {
                            $('body').append('<div class="add-to-cart-messages"></div>');
                        }
                        $('.add-to-cart-messages').append(
                            `${'<div class="alert alert-danger add-to-basket-alert text-center"'
                            + ' role="alert">'}${
                                data.errorMessage}</div>`
                        );
                        setTimeout(() => {
                            $('.add-to-basket-alert').remove();
                        }, 3000);
                    } else {
                        $('.configure-bonus-product-attributes').html(data);
                        $('.bonus-products-step2').removeClass('hidden-xl-down');
                        $('#chooseBonusProductModal').modal('hide');

                        if ($('.add-to-cart-messages').length === 0) {
                            $('body').append('<div class="add-to-cart-messages"></div>');
                        }
                        $('.minicart-quantity').html(data.totalQty);
                        $('.add-to-cart-messages').append(
                            `${'<div class="alert alert-success add-to-basket-alert text-center"'
                            + ' role="alert">'}${
                                data.msgSuccess}</div>`
                        );
                        setTimeout(() => {
                            $('.add-to-basket-alert').remove();
                            if ($('.cart-page').length) {
                                location.reload();
                            }
                        }, 1500);
                    }
                },
                error() {
                    $.spinner().stop();
                },
            });
        });
    }
}
