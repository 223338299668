import Panel from 'common/components/panel';
import { appendToUrl } from 'common/cart/cart';
import { updateWishlistButtonLayout } from 'liujo/product/wishlistHeart';
import { onProductsImpression, onPromosImpression, onProductClick, onPromoClick } from 'common/seo/gtmEvents';
import { findProductInArrayObjects } from 'common/search/search';

export function updateCounter(count) {
    const $target = $('.wishlist-quantity.counter');

    if (!$target.length) {
        return;
    }

    $target.text(count).toggle(count > 0);
}

export default class MiniWishlist {
    constructor() {
        this.miniwishlist = new Panel('.panel--wishlist', { loader: true });
        this.url = this.miniwishlist.$panel.data('action');
        this.update = true;
    }

    init() {
        this.events();
    }

    events() {
        // Event not on body, navbar stops propagation
        $('.page-header .nav').on('click', '.wishlist-open', (e) => {
            e.preventDefault();
            this.open();
        });

        $(document).on('wishlist:afterAddToList', () => {
            this.miniwishlist.setLoading();
            this.open(true);
        });

        $('body')
            .on('click', '.wishlist-close', () => {
                this.miniwishlist.close();
            })
            .on('click', '.remove-from-miniwishlist', (e) => {
                e.preventDefault();

                const actionUrl = $(e.currentTarget).data('action');
                const productID = $(e.currentTarget).data('pid');
                const productName = $(e.currentTarget).data('name');
                const uuid = $(e.currentTarget).data('uuid');
                this.confirmDelete(actionUrl, productID, productName, uuid);
            })
            .on('click', '.wishlist-delete-confirmation-btn', this.remove.bind(this))
            .on('wishlist:update', this.updateContent.bind(this));

        $(document).on('click', '.card--wishlist a, .panel--wishlist a', () => {
            this.onClickProduct.bind(this);
        });
    }

    open(load = false) {
        this.miniwishlist.open()
            .then(() => {
                if (load === false && this.update === false) {
                    return;
                }

                this.updateContent();
            });
    }

    updateContent() {
        const self = this;

        $.ajax({
            url: this.url,
            type: 'get',
            dataType: 'html',
            success(response) {
                self.update = false;
                self.miniwishlist.setContent(response);
                const count = $(response).find('.wishlist-quantity').data('quantity');
                updateCounter(parseInt(count, 10));
                self.onWishlistShow();
            },
            error(err) {
                self.miniwishlist.close();
                console.warn(err);
            },
        });
    }

    confirmDelete(actionUrl, productID, productName, uuid) {
        const $deleteConfirmBtn = $('.wishlist-delete-confirmation-btn');
        const $productToRemoveSpan = $('.product-to-remove');

        $deleteConfirmBtn.data('pid', productID);
        $deleteConfirmBtn.data('action', actionUrl);
        $deleteConfirmBtn.data('uuid', uuid);

        $productToRemoveSpan.empty().append(productName);
    }

    remove(e) {
        e.preventDefault();

        const productID = $(e.currentTarget).data('pid');
        let url = $(e.currentTarget).data('action');
        const uuid = $(e.currentTarget).data('uuid');
        const urlParams = {
            pid: productID,
            uuid,
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();

        $.ajax({
            url,
            type: 'get',
            dataType: 'html',
            success() {
                updateWishlistButtonLayout($(`.add-to-wishlist[data-pid="${productID}"]`), 'remove');

                $('body').trigger('wishlist:update');
            },
            complete() {
                // Force reload if we are in the account page & lj site
                if (($('.page[data-action="Account-Show"]').length || $('.page[data-action="Wishlist-Show"]').length) && $('.dashboard-container').hasClass('scope-lj')) {
                    location.reload();
                } else {
                    $.spinner().stop();
                }
            },
        });
    }

    onWishlistShow() {
        onProductsImpression(window.wishListProducts, window.currency);
        onPromosImpression(window.wishListPromos);
    }

    onClickProduct(e) {
        // Handle productClick
        if (!window.wishListProducts.length) {
            return;
        }

        const currentProductUrl = e.target.href || e.target.parentNode.href;
        const productObject = findProductInArrayObjects(currentProductUrl, window.wishListProducts[0]);
        const promoObject = findProductInArrayObjects(currentProductUrl, window.wishListPromos[0]);
        if (Object.keys(promoObject).length !== 0) {
            onPromoClick([promoObject]);
        }
        onProductClick([productObject], currentProductUrl);

        e.stopPropagation();
    }
}
