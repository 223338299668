

module.exports = function (formElement, ajaxSubmitFunction) {
    const grecaptchaElement = formElement.find('.g-recaptcha');
    const widgetNumber = parseInt(grecaptchaElement.data('widget-number'), 10) || 0;
    /* eslint-disable */
    if (grecaptchaElement.length > 0 && grecaptchaElement.data('size') === 'invisible' && !grecaptcha.getResponse(widgetNumber)) {
        window[grecaptchaElement.data('callback')] = ajaxSubmitFunction;
        grecaptcha.ready(() => {
            grecaptcha.execute(widgetNumber).then(() => { }).catch(() => { });
        });
    } else {
        ajaxSubmitFunction();
    }
    /* eslint-enable */
};
