export default class FormFrame {
    constructor() {
        this.$ = $ || window.jQuery;

        this.frame = '.form-frame';
        this.frameSelected = '.form-frame--selected';
        this.frameSelectable = '.form-frame--selectable';
        this.frameDisabled = '.form-frame--disabled';
        this.frameContent = '.form-frame__toggleable-content';

        this.toggler = `${this.frame} [data-form-framed]`;
    }

    /**
     * Initializes class methods
     */
    init() {
        this.events();
    }

    /**
     * Binds events
     */
    events() {
        const {
            $,
            toggler,
        } = this;

        $(document.body).on('change focus blur', toggler, this.handleStatusChange.bind(this));
    }

    /**
     * Handles events binded to form frame
     * @param {event} e The event
     */
    handleStatusChange(e) {
        const {
            $,
            frame,
            frameSelected,
            frameDisabled,
            frameContent,
        } = this;

        const $el = $(e.target);
        const $frame = $el.closest(frame);
        const disabledClassName = frameDisabled.substring(1);
        const selectedClassName = frameSelected.substring(1);

        if (!$frame || $frame.hasClass(disabledClassName)) {
            // No frame found: return
            return;
        }

        if ($frame.hasClass(disabledClassName)) {
            // Frame is disabled: stop events and return
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        const { tagName } = $el.get(0);
        const tagType = $el.attr('type') || '';
        const eventType = e.type;

        const discriminant = `${tagName}${tagType}-${eventType}`.toUpperCase();
        // TODO: Implement logic for other form elements below, if needed
        switch (discriminant) {
        // Input radio change
        case 'INPUTRADIO-CHANGE':
            $(`input[name="${$el.attr('name')}"]`)
                .each((i, inputEl) => {
                    const $input = $(inputEl);

                    if ($input.is(':checked')) {
                        $input
                            .closest(frame)
                            .addClass(selectedClassName)
                            .find(frameContent)
                            .slideDown();

                        $(document).trigger('formframe:checked', $input);
                    } else {
                        $input
                            .closest(frame)
                            .removeClass(selectedClassName)
                            .find(frameContent)
                            .slideUp();

                        $(document).trigger('formframe:unchecked', $input);
                    }
                });
            break;
        default:
        }
    }
}
