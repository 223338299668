import WishlistHeart from 'common/product/wishlistHeart';
import { onAddToWishlist } from 'common/seo/gtmEvents';

const ACTIONS = {
    add: 'add',
    remove: 'remove',
};

/**
 * Updates the wishlist button layout given an action.
 * @param {string} action One of ACTIONS.add or ACTIONS.remove
 */
export function updateWishlistButtonLayout(el, action) {
    let pid = $(el).closest('.product').data('pid');

    // In PDP, the pid is in a different place..
    if (!pid) {
        pid = $(el).closest('.product-detail').find('.product-id').html();
    }

    if (!pid) {
        return;
    }
    let newEl = $(`[data-pid="${pid}"]`).hasClass('add-to-wishlist') ? $(`[data-pid="${pid}"]`) : $(`[data-pid="${pid}"]`).find('.add-to-wishlist');
    if (!newEl.length) {
        newEl = $(el);
    }
    if (action === ACTIONS.add) {
        newEl.addClass('state-selected');
    } else {
        newEl.removeClass('state-selected');
    }

    newEl.data('wishlist-action', action === ACTIONS.add ? ACTIONS.remove : ACTIONS.add);
}

function messageWishlist() {
    const heightHeader = Math.floor($('.pre-header').outerHeight()) + Math.floor($('.page-header').outerHeight());
    const popup = $('.message-wishlist');
    if (window.breakpoints.atLeast('md')) {
        popup.css({ top: heightHeader });
    } else {
        popup.css({ top: 'auto' });
    }
    if ($('.site-search.panel.panel--site-search.search-lj').hasClass('show') && !window.breakpoints.atLeast('lg')) return;
    popup.addClass('d-flex').fadeIn();
    setTimeout(() => {
        popup.removeClass('d-flex').fadeOut();
    }, 3500);
}

export default class extends WishlistHeart {

    addToWishlist() {
        $('body').on('click', '.add-to-wishlist', function (e) {
            e.preventDefault();
            const $link = $(this);
            const action = $link.data('wishlist-action');
            const url = action === ACTIONS.add
                ? $link.data('add-url')
                : $link.data('remove-url');
            const method = action === ACTIONS.add
                ? 'post'
                : 'get';
            const isPlp = $link.data('plp');
            let pid = $(this).closest('.product').data('pid');

            // In PDP, the pid is in a different place..
            if (!pid) {
                pid = $(this).closest('.product-detail').find('.product-id').html();
            }

            if (!url || !pid) {
                return;
            }

            $.spinner().start();
            $.ajax({
                url,
                type: method,
                dataType: 'json',
                data: {
                    pid,
                    optionId: null,
                    optionVal: null,
                },
                success(data) {
                    if (data.success) {
                        // $link.toggleClass('active');
                        if (action === ACTIONS.add) {
                            if (window.dataLayer && window.dataLayer[0]) {
                                const pdpProducts = data.pdpProducts;
                                pdpProducts[0].item_list_id = window.dataLayer[0].page_type;
                                pdpProducts[0].item_list_name = window.dataLayer[0].site_section_3;
                                onAddToWishlist(pdpProducts);
                            }
                            updateWishlistButtonLayout($link, ACTIONS.add);
                            if (isPlp) messageWishlist();
                        }
                        if (action === ACTIONS.remove) {
                            updateWishlistButtonLayout($link, ACTIONS.remove);
                        }
                    }
                },
                complete() {
                    $.spinner().stop();
                    $(document).trigger('wishlist:afterAddToList');
                },
            });
        });
    }
}
