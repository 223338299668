import Cookie from 'common/util/Cookie';
import Resource from 'common/util/Resource';

export default class Newsletter {
    constructor() {
        this.$modal = $('#newsletter-modal');
        this.$consentTracking = $('#consent-tracking');
        this.cookieName = Resource.getSitePreference('cookiesConfigs', 'newsletterCookieName');
        this.cookieDuration = Resource.getSitePreference('cookiesConfigs', 'newsletterCookieDuration');

        this.settings = {
            duration: 3000,
        };
    }

    init() {
        this.events();
        this.close();
        this.checkConsentTracking();
    }

    events() {
        if (!Cookie.exists(this.cookieName)) {
            setTimeout(() => {
                this.open();
            }, this.settings.duration);

            $(document)
                .on('breakpoints:change', () => {
                    $('body').trigger('consentTracking:check');
                });

            $('body').on('submit', 'form.newsletter', () => {
                Cookie.create(this.cookieName, this.cookieName, this.cookieDuration);
            });
        }
    }

    open() {
        if (window.isPageDesignerMode) {
            return;
        }

        this.$modal.modal('show');
        $('body').trigger('consentTracking:check');

        this.$modal.on('shown.bs.modal', () => {
            $(document).off('focusin.modal');
        });
    }

    close() {
        this.$modal.on('hide.bs.modal', () => {
            Cookie.create(this.cookieName, this.cookieName, this.cookieDuration);
        });
    }

    checkConsentTracking() {
        $('body').on('consentTracking:check', () => {
            if ($('body').find(this.$consentTracking).length) {
                const cookieHeight = this.$consentTracking.outerHeight() + 20;
                this.$modal.css('margin-bottom', cookieHeight);
            } else {
                this.$modal.css('margin-bottom', '0');
            }
        });
    }
}
