export default class MultipleText {
    init() {
        const multipleTextContainer = $('#multiple-text');
        if (multipleTextContainer.length > 0) {
            const multipleTextContainerElements = multipleTextContainer.find('span');
            if (multipleTextContainerElements.length > 1) {
                // create array with all text list
                const multipleTextContainerElementsList = [];
                multipleTextContainerElements.each((index, element) => {
                    multipleTextContainerElementsList.push($(element).text());
                });
                // remove all element except first
                multipleTextContainerElements.not(':first').remove();
                // add visible class (show element)
                multipleTextContainer.addClass('show-multiple-text');
                let count = 0;
                setInterval(() => {
                    count++;
                    $(multipleTextContainer.find('span')).fadeOut(400, function () {
                        $(this).text(multipleTextContainerElementsList[count % multipleTextContainerElementsList.length]).fadeIn(400);
                    });
                }, 4000);
            }
        }
    }
}
