/* CONST Includes */
const PRODUCTIMPRESSION = 'view_item_list';
const PROMOIMPRESSION = 'view_promotion';
const PRODUCTCLICK = 'select_item';
const PROMOCLICK = 'select_promotion';
const DETAIL = 'view_item';
const ADDTOCART = 'add_to_cart';
const REMOVEFROMCART = 'remove_from_cart';
const CART = 'view_cart';
const PURCHASE = 'purchase';
const ADDTOWISHLIST = 'add_to_wishlist';
const GAEVENT = 'gaEvent';
const APPLYPLPFILTERS = 'filtri_apply';
const RESETPLPFILTERS = 'filtri_reset';
const COLMAR_APPLYPLPFILTERS = 'Filtri Apply';
const COLMAR_RESETPLPFILTERS = 'Filtri Reset';
const VIEWMODEPLP = 'view_mode_plp';
const GIFTKIT = 'gif_kit';
const NEWSLETTER = 'newsletter_signup';
const FORM = 'form_submit';
const EXPRESSCHECKOUT = 'express_checkout';
const LOYALTYBANNER = 'loyalty_banner_click';
const LOYALTYLINK = 'loyalty_link_click';
const LOYALTYPOPUP = 'loyalty_popup';
const PRODUCTACTION = 'product_action';
const PRODUCTACTIONS = {
    swipe: 'img_swipe_plp',
    vto: 'virtual_tryon',
    sizeWislist: 'select_size_wishlist',
};

/**
 * Measures product impressions and also tracks a standard
 * pageview for the tag configuration.
 * Product impressions are sent by pushing an impressions object
 * containing one or more impressionFieldObjects.
 * @param {Object} products An object representing a product.
 * @param {string} currency currency code of site.
 */
export function onProductsImpression(productsLists, currency) {
    if (!productsLists || productsLists.length === 0) {
        return;
    }

    const products = JSON.parse(JSON.stringify(productsLists[0]));
    if (!products || products.length === 0) {
        return;
    }
    const productsCurrentChunk = products.splice(0, 20);

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: PRODUCTIMPRESSION,
        ecommerce: {
            currency,
            items: productsCurrentChunk,
        },
    });
    if (products) {
        onProductsImpression([products], currency);
    }
}

/**
 * Measures promotions impressions and also tracks a standard
 * pageview for the tag configuration.
 * Promotions impressions are sent by pushing an impressions object
 * containing one or more impressionFieldObjects.
 * @param {Array} promosLists An list representing a promotions.
 * @param {string} currency currency code of site.
 */
export function onPromosImpression(promosLists) {
    if (!promosLists || promosLists.length === 0) {
        return;
    }

    const promos = promosLists[0] ? JSON.parse(JSON.stringify(promosLists[0])) : [];
    if (!promos || promos.length === 0) {
        return;
    }
    const promosCurrentChunk = promos.splice(0, 20);
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: PROMOIMPRESSION,
        ecommerce: {
            items: promosCurrentChunk,
        },
    });
    if (promos) {
        onPromosImpression([promos]);
    }
}

/**
 * Call this function when a user clicks on a product link. This function uses the event
 * callback datalayer variable to handle navigation after the ecommerce data has been sent
 * to Google Analytics.
 * @param {Object} item Product clicked.
 */
export function onProductClick(item) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: PRODUCTCLICK,
        ecommerce: {
            currency: window.currency,
            items: item,
        },
    });
}

/**
 * Call this function when a user clicks on a product link. This function uses the event
 * callback datalayer variable to handle navigation after the ecommerce data has been sent
 * to Google Analytics.
 * @param {Object} item Product clicked.
 */
export function onPromoClick(item) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: PROMOCLICK,
        ecommerce: {

            items: item,
        },
    });
}

/**
 * Measure a view of product details. This example assumes the detail view occurs on pageload,
 * and also tracks a standard pageview of the details page.
 * @param {Object} item An object representing a product.
 */
export function onProductView(item) {
    const layer = {
        event: DETAIL,
        ecommerce: {
            currency: window.currency,
            items: item,
        },
    };
    if (window.pdpEventInfo.size) {
        layer.ecommerce.size = window.pdpEventInfo.size;
    }
    if (window.pdpEventInfo.InStock) {
        layer.ecommerce.inStock = window.pdpEventInfo.InStock;
    }
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(layer);
}

/**
 * Measure adding a product to a shopping cart by using an 'add' actionFieldObject
 * and a list of productFieldObjects.
 * @param {string} currency currency of the current session.
 * @param {Array} item An array representing products.
 */
export function onAddToCart(currency, item) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: ADDTOCART,
        ecommerce: {
            currency,
            items: item,
        },
    });
}

/**
 * Measure the removal of a product from a shopping cart.
 * @param {string} currency currency of the current session.
 * @param {Array} items An array representing products.
 */
export function onRemoveFromCart(currency, items) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: REMOVEFROMCART,
        ecommerce: {
            currency,
            items,
        },
    });
}

/**
 * A function to handle a click on a checkout button. This function uses the eventCallback
 * data layer variable to handle navigation after the ecommerce data has been sent to Google Analytics.
 * @param {Object} items An object representing a product.
 * @param {string} step Current step of checkout
 */
export function onCheckoutProgress(items, step) {
    const eventExists = window.dataLayer.some(entry => entry.event === step);
    if (!eventExists) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
            event: step,
            ecommerce: {
                currency: window.currency,
                value: window.basketInfo[0].value,
                coupon: window.basketInfo[0].coupon || '',
                itemNumber: window.basketInfo[0].itemNumber,
                items: items[0],
            },
        });
    }
}

/**
 * Send transaction data with a pageview if available
 * when the page loads.
 * @param {Array} items An array representing products.
 */
export function onOrderConfirmation(items, basketInfo) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: PURCHASE,
        ecommerce: {
            currency: window.currency,
            value: basketInfo[0].value,
            tax: basketInfo[0].tax,
            shipping: basketInfo[0].shippingCost,
            transaction_id: basketInfo[0].id,
            fee_cod: basketInfo[0].feeCod,
            coupon: basketInfo[0].coupon || '',
            shipping_method: basketInfo[0].shippingID,
            payment_method: basketInfo[0].paymentID,
            items: items[0],
        },
    });
}

/**
 * Send view cart when the page loads.
 * @param {Array} items An array representing products.
 * @param {Array} datalayer An array representing datalayer.
 */
export function onCartView(items) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: CART,
        ecommerce: {
            currency: window.currency,
            value: window.basketInfo[0].value,
            itemNumber: window.basketInfo[0].itemNumber,
            items: items[0],
        },
    });
}

/**
 * Send add to wishlist when the user clicks on the heart
 * @param {string} action origin of the action: PLP, PDP, HP, LP
 * @param {string} label sku of the product
 */
export function onAddToWishlist(item) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: ADDTOWISHLIST,
        ecommerce: {
            currencyCode: window.currency,
            items: item,
        },
    });
}

/**
 * Send product action when user swipes on image in PLP
 * @param {string} label sku of the product
 */
export function onProductAction(label, action) {
    window.dataLayer.push({
        event: PRODUCTACTION,
        action: PRODUCTACTIONS[action],
        label,
    });
}

/**
 * Send filter information when user applies filters in PLP
 * when multiple filters are applied at once, we send an event for each filter
 * @param filters object with filters applied
 */
export function onApplyPlpFilter(filters) {
    for (let i = 0; i < Object.keys(filters).length; i++) {
        const filter = Object.keys(filters)[i];
        if (window.location.href.includes('liujo')) {
            window.dataLayer.push({
                event: APPLYPLPFILTERS,
                filter_type: filter.replace('refinement', ''),
                filter_name: filters[filter].join('|'),
            });
        } else {
            window.dataLayer.push({
                event: GAEVENT,
                eventCategory: COLMAR_APPLYPLPFILTERS,
                eventAction: filter.replace('refinement', ''),
                eventLabel: filters[filter].join('|'),
            });
        }
    }
}

/**
 * Send reset filter information when user resets filters in PLP
 */
export function onResetPlpFilter() {
    if (window.location.href.includes('liujo')) {
        window.dataLayer.push({
            event: RESETPLPFILTERS,
        });
    } else {
        window.dataLayer.push({
            event: GAEVENT,
            eventCategory: COLMAR_RESETPLPFILTERS,
        });
    }
}

/**
 * Send event when user changes view mode in PLP
 * @param {string} label sku of the product
 */
export function onViewModeChange(view) {
    window.dataLayer.push({
        event: VIEWMODEPLP,
        view_mode_type: view,
    });
}

/**
 * Send event when user clicks on is a gift kit
 * @param {string} view the view form where the click is fired
 */
export function onGiftKitSelection(view) {
    window.dataLayer.push({
        event: GIFTKIT,
        action: view,
    });
}

/**
 * Send event when user sumbits newsletter form
 * @param {string} view the view form where submit is fired
 */
export function onNewsletterSubmit(view) {
    window.dataLayer.push({
        event: NEWSLETTER,
        action: view,
    });
}

/**
 * Send event when user interacts with shop the look
 * @param {string} event the event performed
 * @param {string} action the action performed
 * @param {string} label the label of the product
 */
export function onShopTheLook(event, action, label) {
    window.dataLayer.push({
        event,
        action,
        label,
    });
}

/**
 * Send event when user interacts with shop the look
 * @param {string} event the event performed
 * @param {string} action the action performed
 * @param {string} label the label of the product
 */
export function onFormSubmit(type, loyalty) {
    window.dataLayer.push({
        event: FORM,
        form_type: type,
        loyalty_consent: loyalty,
    });
}

/**
 * Send event when user interacts with shop the look
 * @param {string} event the event performed
 * @param {string} action the action performed
 * @param {string} label the label of the product
 */
export function onExpressCheckout(type, item) {
    window.dataLayer.push({
        event: EXPRESSCHECKOUT,
        payment_type: type,
        item_sku: item,
    });
}


/**
 * Sends an event to the Google Tag Manager dataLayer when the loyalty banner is clicked.
 * @param {string} type - The type of the loyalty banner.
 * @param {string} item - The text of the clicked item.
 */
export function onLoyaltyBanner(type, item) {
    window.dataLayer.push({
        event: LOYALTYBANNER,
        oyalty_banner_type: type,
        click_text: item,
    });
}

/**
 * Sends a GTM event when a loyalty link is clicked.
 * @param {string} type - The type of loyalty link.
 * @param {string} item - The text of the clicked link.
 */
export function onLoyaltyLink(type, item) {
    window.dataLayer.push({
        event: LOYALTYLINK,
        loyalty_link_type: type,
        click_text: item,
    });
}

/**
 * Sends a GTM event for loyalty popup.
 *
 * @param {string} type - The type of action performed on the loyalty popup.
 * @param {string} item - The item associated with the action performed on the loyalty popup.
 */
export function onLoyaltyPopup(type, item) {
    window.dataLayer.push({
        event: LOYALTYPOPUP,
        action: type,
        lable: item,
    });
}

