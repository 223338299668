export default class Select {
    constructor(selector = 'select.custom-select:not(.no-select2') {
        this.selector = selector;
        this.excluded = ['Returns-Show', 'Cart-Show', 'Checkout-Begin', 'Wishlist-Show'];
    }

    init() {
        // Do not initialize in excluded pages
        if (this.excluded.indexOf($('.page').data('action')) > -1) {
            return;
        }

        $(this.selector).select2({
            minimumResultsForSearch: Infinity,
        });
    }
}
