import { debounce } from 'lodash';

/**
 * Get the SCSS-defined breakpoints from a DOM pseudo-element
 * @param {String} pseudoElement The body's pseudoelement to get the breakpoints from, e.g. ::before or ::after
 * @returns {String} The document's raw breakpoints as a comma-separated list of key:value pairs
 */
export function getRawBreakpointsFromDOM(pseudoElement = '::before') {
    return window
        .getComputedStyle(document.body, pseudoElement)
        .getPropertyValue('content')
        .replace(/^"(.+)"$/, '$1')
        .replace(/,$/, '');
}

/**
 * Parse the raw string of breakpoints into a JS Object
 * @param {String} rawBreakpoints A comma-separated list of key:value pairs of breakpoints and pixel values
 * @returns {Object} The current document's possible breakpoints as key{String}:value{Number} pair
 */
export function parseBreakpoints(rawBreakpoints) {
    let breakpoints = {};

    // Remove leading and trailing double quotes, then remove last comma
    rawBreakpoints.split(',').forEach((el) => {
        const key = el.split(':')[0];
        const val = el.split(':')[1].replace('px', '');
        breakpoints = Object.assign({ [key]: parseInt(val, 10) }, breakpoints);
    });

    return breakpoints;
}

/**
 * A set of tools to handle breakpoints change events and methods to compare them
 */
export default class Breakpoints {
    constructor() {
        this.breakpoints = parseBreakpoints(getRawBreakpointsFromDOM('::before'));
        this.current = getRawBreakpointsFromDOM('::after');
        $(window).on('resize', debounce(() => {
            const current = this.current;
            const next = getRawBreakpointsFromDOM('::after');

            if (current === next) {
                return;
            }

            this.current = next;

            $(document).trigger('breakpoints:change', { current: next, prev: current });
        }, 300));
    }

    /**
     * Get the current breakpoint name
     * @returns {String} The current breakpoint
     */
    getCurrent() {
        return this.current;
    }

    /**
     * Get a specified breakpoint value
     * @param {String} breakpoint The 2-letter string identifying the breakpoint
     * @return {Number} The pixel width value of the requested breakpoint
     */
    get(breakpoint) {
        return this.breakpoints[breakpoint];
    }

    /**
     * Checks the current breakpoint for a specifiend name
     * @param {String} breakpoint The 2-letter string identifying the breakpoint
     * @return {Boolean}
     */
    is(breakpoint) {
        return breakpoint === this.current;
    }

    /**
     * Checks if the current breakpoint is at least the specified one
     * @param {String} breakpoint The 2-letter string identifying the breakpoint
     * @return {Boolean}
     */
    atLeast(breakpoint) {
        return this.get(this.current) >= this.get(breakpoint);
    }
}

/**
 * @returns {Boolean} If the current window has the breakpoints property
 */
export function checkBreakpoints() {
    return 'breakpoints' in window && window.breakpoints instanceof Breakpoints;
}
