import { throttle } from 'lodash';
import AmazonPayButtonPDP from 'common/product/components/amazonpayButtonPDP';
import ProductBase from '../product/base';
import focusHelper from '../components/focus';
import { onCartView, onAddToCart, onRemoveFromCart, onProductsImpression, onPromosImpression } from '../seo/gtmEvents';

const base = new ProductBase();
const amazonPayButtonPDP = new AmazonPayButtonPDP();

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
export function appendToUrl(url, params) {
    let newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map((key) => `${key}=${encodeURIComponent(params[key])}`).join('&');

    return newUrl;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
export function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            // const errorHtml = `${'<div class="alert alert-danger alert-dismissible valid-cart-error ' +
            //     'fade show" role="alert">' +
            //     '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
            //     '<span aria-hidden="true">&times;</span>' +
            //     '</button>'}${data.valid.message}</div>`;

            // $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().append(`${'<div class="row"> ' +
                '<div class="col-12 text-center"> ' +
                '<h1>'}${data.resources.emptyCartMsg}</h1> ` +
                '</div> ' +
                '</div>'
            );
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems,
            });

            $(document).trigger('cart:empty');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

export function clickAndCollectShowStore(data) {
    if (data.shipments[0] && data.shipments[0].selectedShippingMethod && data.shipments[0].selectedShippingMethod.indexOf('click_and_collect') > -1 && !!data.enableSelectionStoresInCart) {
        $('.instore-pickup-shipping').removeClass('d-none').show();
        $('body').trigger('pickup:selected');
    } else if (data.enableSelectionStoresInCart) {
        $('.instore-pickup-shipping').removeClass('d-block').hide();
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
export function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems,
    });
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append(`- ${data.totals.orderLevelDiscountTotal.formatted}`);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }
    $(document).trigger('cart:updateTotals', data);

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append(`- ${
            data.totals.shippingLevelDiscountTotal.formatted}`);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    data.items.forEach((item) => {
        if (item.renderedPromotions) {
            $(`.item-${item.UUID}`).empty().append(item.renderedPromotions);
        }
        if (item.priceTotal && item.priceTotal.renderedPrice) {
            $(`.item-total-${item.UUID}`).empty().append(item.priceTotal.renderedPrice);
        }
    });
}

/**
 * update shipping methods costs after quantity, method, or item changes
 * @param {Object} data - AJAX response from the server
 */
export function updateShippingMethodsCosts(data) {
    try {
        data.shipments[0].shippingMethods.forEach((method) => {
            const $target = $(`#shippingMethods-cost-${method.ID}`);

            if ($target.length) {
                $target.text(method.shippingCost);
            }
        });
    } catch (error) {
        console.warn(error);
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
export function createErrorNotification(message) {
    const errorHtml = `${'<div class="alert alert-danger alert-dismissible valid-cart-error ' +
    'fade show" role="alert">' +
    '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
    '<span aria-hidden="true">&times;</span>' +
    '</button>'}${message}</div>`;

    $('.cart-error').append(errorHtml);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
export function updateApproachingDiscounts(approachingDiscounts) {
    let html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach((item) => {
            html += `<div class="single-approaching-discount text-center">${
                item.discountMsg}</div>`;
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
export function updateAvailability(data, uuid) {
    let lineItem;
    let messages = '';

    for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (!lineItem) {
        return;
    }

    $(`.availability-${lineItem.UUID}`).empty();

    if (lineItem.availability) {
        if (lineItem.availability.messages) {
            lineItem.availability.messages.forEach((message) => {
                messages += `<div><span class="small text-gray-700 ${!lineItem.available ? 'not-available' : ''}">${message}</span></div>`;
            });
        }

        if (lineItem.availability.inStockDate) {
            messages += `<p class="line-item-attributes line-item-instock-date">${
                lineItem.availability.inStockDate
            }</p>`;
        }
    }

    $(`.availability-${lineItem.UUID}`).html(messages);
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
export function findItem(array, match) {
    for (let i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
export function updateProductDetails(data, uuid) {
    const lineItem = findItem(data.cartModel.items, (item) => item.UUID === uuid);

    if (lineItem.options && lineItem.options.length) {
        const option = lineItem.options[0];
        const optSelector = `.lineItem-options-values[data-option-id="${option.optionId}"]`;
        $(optSelector).data('value-id', option.selectedValueId);
        $(`${optSelector} .line-item-attributes`).text(option.displayName);
    }

    const qtySelector = `.quantity[data-uuid="${uuid}"]`;

    // Re-build options select
    $(qtySelector).empty();
    for (let i = lineItem.quantityOptions.minOrderQuantity; i <= lineItem.quantityOptions.maxOrderQuantity; i++) {
        const option = new Option(i, i, i === lineItem.quantity, i === lineItem.quantity);
        $(qtySelector).append($(option)); // do not trigger change event here to avoid ajax loop!
    }

    $(qtySelector).val(lineItem.quantity);
    $(qtySelector).data('pid', data.newProductId);

    $(`.remove-product[data-uuid="${uuid}"]`).data('pid', data.newProductId);

    const priceSelector = `.line-item-price-${uuid} .sales .value`;
    $(priceSelector).text(lineItem.price.sales.formatted);
    $(priceSelector).attr('content', lineItem.price.sales.decimalPrice);

    if (lineItem.price.list) {
        const listPriceSelector = `.line-item-price-${uuid} .list .value`;
        $(listPriceSelector).text(lineItem.price.list.formatted);
        $(listPriceSelector).attr('content', lineItem.price.list.decimalPrice);
    }
}

/**
 * Generates the modal window on the first call.
 *
 */
export function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    const htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="editProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
export function parseHtml(html) {
    const $html = $('<div>').append($.parseHTML(html));

    const body = $html.find('.product-quickview');
    const footer = $html.find('.modal-footer').children();

    return { body, footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
export function fillModalElement(editProductUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success(data) {
            const parsedHtml = parseHtml(data.renderedTemplate);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal').modal('show');
            $.spinner().stop();
        },
        error() {
            $.spinner().stop();
        },
    });
}

/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} productID - Product ID
 * @param {Object} productsPdpArray - Ajax response HTML code
 * @return {Object} Return product object
 */
export function findProductInArrayObjects(productID, productsPdpArray) {
    let found = false;
    let arrLength = 0;
    let product = {};

    while (arrLength < productsPdpArray.length && !found) {
        if (productID === productsPdpArray[arrLength].item_id) {
            product = productsPdpArray[arrLength];
            found = true;
        }
        arrLength++;
    }

    return product;
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
export function confirmDelete(actionUrl, productID, productName, uuid) {
    const $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    const $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);

    $productToRemoveSpan.empty()
        .append(productName);
    const productObject = findProductInArrayObjects(productID, window.lineItemCtnrProducts[0]);

    onRemoveFromCart(window.currency, productObject);
}
export function updateLineItemVariation(updateProductUrl, form, uuid, product, $product, resources) {
    if (updateProductUrl && product.variationAttributes) {
        // Update the product's size select with the new values
        const $size = $product.find('select[name="line-item-size"]').empty();
        const sizeAttribute = product.variationAttributes.filter((attr) => attr.attributeId === 'size')[0];

        if (sizeAttribute) {
            let selectedSize;
            const values = sizeAttribute.values;

            if (!values.length) {
                return;
            }

            values.filter((value) => value.selectable).forEach((value) => {
                const option = new Option(value.displayValue, value.value, value.selected, value.selected);
                const $option = $(option).attr('data-variation-url', value.url);
                $size.append($option); // do not trigger change event here to avoid ajax loop!

                if (value.selected) {
                    selectedSize = value;
                }
            });

            $size.blur();

            if (selectedSize) {
                const label = `${resources.sizeLabel}: ${selectedSize.displayValue}`;
                const variantItemCode = product && product.itemCode ? product.itemCode : null;
                $product.find('.item-attributes .size').text(label);

                if (variantItemCode && $product.find('.item-attributes .product-id')) {
                    $product.find('.item-attributes .product-id').text(variantItemCode);
                }
            }
        }

        // Update the product's quantity select with the new minimum and maximum

        // Then update the cart with the new information
        $.ajax({
            url: updateProductUrl,
            type: 'post',
            context: this,
            data: form,
            dataType: 'json',
            success(data) {
                $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                updateCartTotals(data.cartModel);
                updateShippingMethodsCosts(data.cartModel);
                updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                updateAvailability(data.cartModel, uuid);
                updateProductDetails(data, uuid);

                if (data.uuidToBeDeleted) {
                    $(`.uuid-${data.uuidToBeDeleted}`).remove();
                }

                validateBasket(data.cartModel);

                $('body').trigger('cart:update');

                $.spinner().stop();
            },
            error(err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            },
        });
    }
}

export default class Cart {
    init() {
        $('body').on('click', '.remove-product:not(.remove-from-wishlist, .remove-from-miniwishlist)', function (e) {
            e.preventDefault();

            const actionUrl = $(this).data('action');
            const productID = $(this).data('pid');
            const productName = $(this).data('name');
            const uuid = $(this).data('uuid');
            confirmDelete(actionUrl, productID, productName, uuid);
        });

        $('body').on('afterRemoveFromCart', (e, data) => {
            e.preventDefault();
            confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
        });

        $('.optional-promo').click((e) => {
            e.preventDefault();
            $('.promo-code-form').toggle();
        });

        $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
            e.preventDefault();

            const productID = $(this).data('pid');
            let url = $(this).data('action');
            const uuid = $(this).data('uuid');
            const urlParams = {
                pid: productID,
                uuid,
            };

            url = appendToUrl(url, urlParams);

            $('body > .modal-backdrop').remove();

            $.spinner().start();
            $.ajax({
                url,
                type: 'get',
                dataType: 'json',
                success(data) {
                    if (data.basket.items.length === 0) {
                        $('.cart').empty();
                        $('.cart-header').replaceWith(data.basket.resources.emptyCartHTML);
                        $('.empty-cart__recommendations').removeClass('d-none').show();
                        window.scrollTo(0, 0);
                        $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                        $('.minicart-quantity').empty().append(data.basket.numItems).hide();
                        $('.minicart-link').attr({
                            'aria-label': data.basket.resources.minicartCountOfItems,
                            title: data.basket.resources.minicartCountOfItems,
                        });

                        $(document).trigger('cart:empty');
                    } else {
                        if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                            for (let i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                                $(`.uuid-${data.toBeDeletedUUIDs[i]}`).remove();
                            }
                        }
                        $(`.uuid-${uuid}`).remove();
                        if (!data.basket.hasBonusProduct) {
                            $('.bonus-product').remove();
                        }
                        $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                        updateCartTotals(data.basket);
                        updateShippingMethodsCosts(data.basket);
                        updateApproachingDiscounts(data.basket.approachingDiscounts);
                        $('body').trigger('setShippingMethodSelection', data.basket);
                        validateBasket(data.basket);
                    }
                    if (data.orderInfo) {
                        window.basketInfo = [data.orderInfo];
                    }
                    const itemIndex = window.lineItemCtnrProducts[0].findIndex(item => item.item_id === productID);
                    if (itemIndex !== -1) window.lineItemCtnrProducts[0].splice(itemIndex, 1);
                    const promoIndex = window.window.lineItemCtnrPromo[0].findIndex(item => item.item_id === productID);
                    if (promoIndex !== -1) window.window.lineItemCtnrPromo[0].splice(itemIndex, 1);
                    $('body').trigger('cart:update');

                    $.spinner().stop();
                },
                error(err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                },
            });
        });

        $('body').on('change', '.quantity-form > .quantity', function () {
            const preSelectQty = $(this).data('pre-select-qty');
            const quantity = $(this).val();
            const productID = $(this).data('pid');
            let url = $(this).data('action');
            const uuid = $(this).data('uuid');

            const urlParams = {
                pid: productID,
                quantity,
                uuid,
            };
            url = appendToUrl(url, urlParams);

            $(this).parents('.card').spinner().start();

            $.ajax({
                url,
                type: 'get',
                context: this,
                dataType: 'json',
                success(data) {
                    $(`.quantity[data-uuid="${uuid}"]`).val(quantity);
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateShippingMethodsCosts(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    updateAvailability(data, uuid);
                    validateBasket(data);
                    if (data.orderInfo) {
                        window.basketInfo = [data.orderInfo];
                    }
                    const products = data.pdpProducts;
                    products[0].item_list_id = 'Cart';
                    products[0].item_list_name = 'Cart';
                    if (Number($(this).data('pre-select-qty')) > Number(quantity)) {
                        products[0].quantity = Number($(this).data('pre-select-qty')) - quantity;
                        onRemoveFromCart(window.currency, products);
                    } else {
                        products[0].quantity = quantity - Number($(this).data('pre-select-qty'));
                        onAddToCart(window.currency, products);
                    }
                    const foundItem = window.lineItemCtnrProducts[0].find(item => item.item_id === products[0].item_id);
                    if (foundItem) foundItem.quantity = Number(quantity);
                    $(this).data('pre-select-qty', quantity);

                    $('body').trigger('cart:update');

                    $.spinner().stop();
                    if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                        location.reload();
                    }
                },
                error(err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $(this).val(parseInt(preSelectQty, 10));
                        $.spinner().stop();
                    }
                },
            });
        });

        $('.shippingMethods').change(function () {
            const url = $(this).data('action-url');
            const urlParams = {
                methodID: $(this).closest('.shippingMethods-form-group').find(':checked').data('shipping-id'),
            };

            $('.totals').spinner().start();
            $.ajax({
                url,
                type: 'post',
                dataType: 'json',
                data: urlParams,
                success(data) {
                    if (data.error) {
                        window.location.href = data.redirectUrl;
                    } else {
                        $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                        updateCartTotals(data);
                        updateShippingMethodsCosts(data);
                        updateApproachingDiscounts(data.approachingDiscounts);
                        validateBasket(data);
                        clickAndCollectShowStore(data);
                    }
                    $.spinner().stop();
                },
                error(err) {
                    if (err.redirectUrl) {
                        window.location.href = err.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                },
            });
        });

        $('.promo-code-form').submit((e) => {
            e.preventDefault();
            $.spinner().start();
            $('.coupon-missing-error').hide();
            $('.coupon-error-message').empty();
            if (!$('.coupon-code-field').val()) {
                $('.promo-code-form .form-control').addClass('is-invalid');
                $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
                $('.coupon-missing-error').show();
                $.spinner().stop();
                return false;
            }
            const $form = $('.promo-code-form');
            $('.promo-code-form .form-control').removeClass('is-invalid');
            $('.coupon-error-message').empty();

            $.ajax({
                url: $form.attr('action'),
                type: 'GET',
                dataType: 'json',
                data: $form.serialize(),
                success(data) {
                    if (data.error) {
                        $('.promo-code-form .form-control').addClass('is-invalid');
                        $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                        $('.coupon-error-message').empty().append(data.errorMessage);
                    } else {
                        $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                        updateCartTotals(data);
                        updateShippingMethodsCosts(data);
                        updateApproachingDiscounts(data.approachingDiscounts);
                        validateBasket(data);
                    }
                    $('.coupon-code-field').val('');
                    $.spinner().stop();
                },
                error(err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.errorMessage);
                        $.spinner().stop();
                    }
                },
            });
            return false;
        });

        $('body').on('click', '.remove-coupon', function (e) {
            e.preventDefault();

            const couponCode = $(this).data('code');
            const uuid = $(this).data('uuid');
            const $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
            const $productToRemoveSpan = $('.coupon-to-remove');

            $deleteConfirmBtn.data('uuid', uuid);
            $deleteConfirmBtn.data('code', couponCode);

            $productToRemoveSpan.empty().append(couponCode);
        });

        $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
            e.preventDefault();

            let url = $(this).data('action');
            const uuid = $(this).data('uuid');
            const couponCode = $(this).data('code');
            const urlParams = {
                code: couponCode,
                uuid,
            };

            url = appendToUrl(url, urlParams);

            $('body > .modal-backdrop').remove();

            $.spinner().start();
            $.ajax({
                url,
                type: 'get',
                dataType: 'json',
                success(data) {
                    $(`.coupon-uuid-${uuid}`).remove();
                    updateCartTotals(data);
                    updateShippingMethodsCosts(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    $.spinner().stop();
                },
                error(err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                },
            });
        });
        $('body').on('click', '.cart-page .bonus-product-button', function () {
            $.spinner().start();
            $(this).addClass('launched-modal');
            $.ajax({
                url: $(this).data('url'),
                method: 'GET',
                dataType: 'json',
                success(data) {
                    base.methods.editBonusProducts(data);
                    $.spinner().stop();
                },
                error() {
                    $.spinner().stop();
                },
            });
        });

        $('body').on('hidden.bs.modal', '#chooseBonusProductModal', () => {
            $('#chooseBonusProductModal').remove();
            $('.modal-backdrop').remove();
            $('body').removeClass('modal-open');

            if ($('.cart-page').length) {
                $('.launched-modal .btn-outline-primary').trigger('focus');
                $('.launched-modal').removeClass('launched-modal');
            } else {
                $('.product-detail .add-to-cart').focus();
            }
        });

        $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function (e) {
            e.preventDefault();

            const editProductUrl = $(this).attr('href');
            getModalHtmlElement();
            fillModalElement(editProductUrl);
        });

        $('body').on('shown.bs.modal', '#editProductModal', () => {
            $('#editProductModal').siblings().attr('aria-hidden', 'true');
            $('#editProductModal .close').focus();
        });

        $('body').on('hidden.bs.modal', '#editProductModal', () => {
            $('#editProductModal').siblings().attr('aria-hidden', 'false');
        });

        $('body').on('keydown', '#editProductModal', (e) => {
            const focusParams = {
                event: e,
                containerSelector: '#editProductModal',
                firstElementSelector: '.close',
                lastElementSelector: '.update-cart-product-global',
                nextToLastElementSelector: '.modal-footer .quantity-select',
            };
            focusHelper.setTabNextFocus(focusParams);
        });

        $('body').on('product:updateAddToCart', (e, response) => {
            // update global add to cart (single products, bundles)
            const dialog = $(response.$productContainer)
                .closest('.quick-view-dialog');

            $('.update-cart-product-global', dialog).attr('disabled',
                !$('.global-availability', dialog).data('ready-to-order')
                || !$('.global-availability', dialog).data('available')
            );
        });

        $('body').on('product:updateAvailability', (e, response) => {
            // bundle individual products
            $('.product-availability', response.$productContainer)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);


            const dialog = $(response.$productContainer)
                .closest('.quick-view-dialog');

            if ($('.product-availability', dialog).length) {
                // bundle all products
                const allAvailable = $('.product-availability', dialog).toArray()
                    .every((item) => $(item).data('available'));

                const allReady = $('.product-availability', dialog).toArray()
                    .every((item) => $(item).data('ready-to-order'));

                $('.global-availability', dialog)
                    .data('ready-to-order', allReady)
                    .data('available', allAvailable);

                $('.global-availability .availability-msg', dialog).empty()
                    .html(allReady ? response.message : response.resources.info_selectforstock);
            } else {
                // single product
                $('.global-availability', dialog)
                    .data('ready-to-order', response.product.readyToOrder)
                    .data('available', response.product.available)
                    .find('.availability-msg')
                    .empty()
                    .html(response.message);
            }
        });

        $('body').on('product:afterAttributeSelect', (e, response) => {
            if ($('.modal.show .product-quickview .bundle-items').length) {
                $('.modal.show').find(response.container).data('pid', response.data.product.id);
                $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
            } else {
                $('.modal.show .product-quickview').data('pid', response.data.product.id);
            }
        });

        $('body').on('change', '.quantity-select', function () {
            const selectedQuantity = $(this).val();
            $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
        });

        $('body').on('change', '.options-select', function () {
            const selectedOptionValueId = $(this).children('option:selected').data('value-id');
            $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
        });

        $('body').on('change', 'select[name="line-item-size"]', function (e) {
            const $product = $(this).closest('.product-info');
            const variationUrl = $(e.currentTarget).find('option:selected').data('variation-url');
            const updateProductUrl = $(this).data('action');
            // Reset quantity to 1 when changing size
            const selectedQuantity = $product.find('select.quantity').val(1).val();
            const uuid = $(this).data('uuid');

            $(this).parents('.card').spinner().start();

            // Retrieve the selected Product Variation ID via AJAX (usually done in product edit modal)
            $.ajax({
                url: variationUrl,
                type: 'get',
                dataType: 'json',
                success(response) {
                    if (response && response.product) {
                        const form = {
                            uuid,
                            pid: response.product.id,
                            quantity: selectedQuantity,
                        };

                        updateLineItemVariation(updateProductUrl, form, uuid, response.product, $product, response.resources);
                    }
                },
                error(response) {
                    $.spinner().stop();
                    console.warn(response);
                },
            });
        });

        $('body').on('click', '.update-cart-product-global', function (e) {
            e.preventDefault();

            const updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
            const selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
            const selectedOptionValueId = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-option');
            const uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

            const form = {
                uuid,
                pid: base.getPidValue($(this)),
                quantity: selectedQuantity,
                selectedOptionValueId,
            };

            $(this).parents('.card').spinner().start();
            if (updateProductUrl) {
                $.ajax({
                    url: updateProductUrl,
                    type: 'post',
                    context: this,
                    data: form,
                    dataType: 'json',
                    success(data) {
                        $('#editProductModal').modal('hide');

                        $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                        updateCartTotals(data.cartModel);
                        updateShippingMethodsCosts(data.cartModel);
                        updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                        updateAvailability(data.cartModel, uuid);
                        updateProductDetails(data, uuid);

                        if (data.uuidToBeDeleted) {
                            $(`.uuid-${data.uuidToBeDeleted}`).remove();
                        }

                        validateBasket(data.cartModel);

                        $('body').trigger('cart:update');

                        $.spinner().stop();
                    },
                    error(err) {
                        if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        } else {
                            createErrorNotification(err.responseJSON.errorMessage);
                            $.spinner().stop();
                        }
                    },
                });
            }
        });

        // Fixed actions on mobile
        function handleCartScroll() {
            if (window.breakpoints.atLeast('sm')) {
                return;
            }

            const $actions = $('.cart__fixed-actions');

            if (!$actions.length) {
                return;
            }

            let offset = $actions.height();
            if ($('.cart-aside .col--partial-total').offset()) {
                offset += $('.cart-aside .col--partial-total').offset().top;
            }

            const scrolled = $(window).scrollTop() + $(window).height();

            if (offset > scrolled) {
                $actions.fadeIn();
            } else {
                $actions.fadeOut();
            }
        }

        $(window).on('scroll', throttle(handleCartScroll, 100));

        base.buttonAttributes();
        base.removeBonusProduct();
        base.selectBonusProduct();
        base.enableBonusProductSelection();
        base.showMoreBonusProducts();
        base.addBonusProductsToCart();
        base.focusChooseBonusProductModal();
        base.trapChooseBonusProductModalFocus();
        base.onClosingChooseBonusProductModal();
        this.applepayClickButtonEvent();

        if ($('#maincontent').find('.cart-page').length > 0) {
            onCartView(window.lineItemCtnrProducts, window.dataLayer);
            onProductsImpression(window.lineItemCtnrProducts, window.currency);
            onPromosImpression(window.lineItemCtnrPromo);
        }
        window.onload = () => {
            if (document.getElementById('AmazonPayButtonCart')) {
                amazonPayButtonPDP.loadStyle('AmazonPayButtonCart');
                document.getElementById('AmazonPayButtonCart').classList.remove('d-none');
            }

            if ($('.has-applePayOnly') && $('.apple-pay-wrapper button') && $('.apple-pay-wrapper button').length === 0) {
                $('.has-applePayOnly').addClass('d-none');
            }
        };
    }

    applepayClickButtonEvent() {
        /*  set click event on applepay button. */
        $('body').on('click', '.cart-page .dw-apple-pay-button', () => {
            /* start a spinner: the page will reload if the user cancel the payment or.  */
            $.spinner().start();
        });
    }
}
