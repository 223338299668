import scrollAnimate from 'common/components/scrollAnimate';
import gRecaptchaAjax from 'common/components/gRecaptchaAjax';
import { onNewsletterSubmit } from 'common/seo/gtmEvents';

const cookiebot = window.Cookiebot;
/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
export function displayMessage(data, button, clickedElement) {
    const $formContainer = $('body').find(clickedElement).parents('.newsletter-container');
    let status;

    if (data.success) {
        status = 'alert-success';
        $formContainer.html(`<p class="email-signup-alert ${status}">${data.msg}</p>`);
    } else if ($formContainer.find('.email-signup-alert').length === 0) {
        status = 'alert-danger';
        $formContainer.find('form.newsletter').prepend(`<p class="email-signup-alert ${status}">${data.msg ? data.msg : data.error[0]}</p>`);
    }

    setTimeout(() => {
        button.removeAttr('disabled');
    }, 3000);
}

export default class Footer {
    constructor() {
        this.$form = $('form.newsletter');
        this.$details = this.$form.find('.newsletter__details');
    }

    init() {
        $('.back-to-top').click(() => {
            scrollAnimate();
        });

        $('body').on('submit', 'form.newsletter', function (e) {
            e.preventDefault();
            const url = $(this).data('href');
            const button = $(this).find('input[type="submit"]');
            const signupFormat = $(e.target).closest('.modal').length > 0 ? 'push_banner' : 'page';
            onNewsletterSubmit(signupFormat);
            button.prop('disabled', true);
            const ajaxSubmit = () => {
                const data = $(this).serialize();
                $.ajax({
                    url,
                    type: 'post',
                    dataType: 'json',
                    data,
                    beforeSend() {
                        $.spinner().start();
                    },
                    success(res) {
                        displayMessage(res, button, $(e.currentTarget));
                    },
                    error(err) {
                        displayMessage(err, button, $(e.currentTarget));
                    },
                    complete() {
                        $.spinner().stop();
                    },
                });
            };
            gRecaptchaAjax($(this), ajaxSubmit.bind(this));
        });

        this.newsletterDetails();
        this.showModalUpdateCookies();
        this.closeModalUpdateCookies();
    }

    newsletterDetails() {
        if (!this.$details.length) {
            return;
        }

        this.$form.find('input[name="emailId"]').on('focus', () => {
            this.$details.slideDown();
        });
    }

    // Function to show the modal to update the cookies
    showModalUpdateCookies() {
        const modalCookiesModify = $('#cookies-modify-modal');
        const btnOpenModalCookiesModify = $('#btn-modify-cookies');
        const preferencesCookies = $('.preferences-cookies span svg');
        const statisticsCookies = $('.statistics-cookies span svg');
        const marketingCookies = $('.marketing-cookies span svg');

        btnOpenModalCookiesModify.on('click', () => {
            modalCookiesModify.show().addClass('show');
            $('body').addClass('modal-open');

            const updateSvg = (element, condition) => {
                if (condition) {
                    element.filter('.checked').removeClass('d-none');
                    element.filter('.unchecked').addClass('d-none');
                } else {
                    element.filter('.unchecked').removeClass('d-none');
                    element.filter('.checked').addClass('d-none');
                }
            };

            updateSvg(preferencesCookies, cookiebot.consent.preferences);
            updateSvg(statisticsCookies, cookiebot.consent.statistics);
            updateSvg(marketingCookies, cookiebot.consent.marketing);
        });
    }

    // Function to close the modal to update the cookies
    closeModalUpdateCookies() {
        const modalCookiesModify = $('#cookies-modify-modal');
        const btnCloseModalCookiesModify = $('#cookies-modify-modal .close');
        const resetCookies = $('.js-reset-consents');
        const changeCookies = $('.js-change-consents');

        btnCloseModalCookiesModify.on('click', () => {
            modalCookiesModify.hide().removeClass('show');
            $('body').removeClass('modal-open');
        });


        resetCookies.on('click', () => {
            cookiebot.withdraw();
            modalCookiesModify.hide().removeClass('show');
            $('body').removeClass('modal-open');
        });


        changeCookies.on('click', () => {
            cookiebot.renew();
            modalCookiesModify.hide().removeClass('show');
            $('body').removeClass('modal-open');
        });
    }
}
