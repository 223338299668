import { throttle } from 'lodash';
import CommonMenu, { buildNavigationRecursively } from 'common/components/menu';
import { checkBreakpoints } from 'common/components/breakpoints';

export default class Menu extends CommonMenu {
    constructor() {
        super();

        this.defaultNavigation = [$('body').data('topLevelCategory')];
        this.toggleEventName = window.breakpoints.atLeast('lg') ? 'mouseenter' : 'click';
        this.headerDelta = 400;
        this.headerHeight = $('header.page-header').outerHeight() / 2;
    }

    init() {
        super.init();
        this.state.navigation = [];

        return this;
    }

    pickMenuFromBreakpoint() {
        if (window.breakpoints.atLeast('lg')) {
            this.$menu = this.$navbar.find('.nav--horizontal');
        } else {
            this.$menu = this.$navbar.find('.nav--vertical');
        }
    }

    events() {
        this.$navbar
            .on('click', this.stopPropagation.bind(this))
            .on('click', '.navbar-toggler', this.show.bind(this))
            .on('click', '.navbar-close, .nav--vertical__header__close', this.hide.bind(this))
            .on('click', '.navbar-back', this.back.bind(this))
            .on(this.toggleEventName, '.nav-link', this.navigate.bind(this));

        if (window.breakpoints.atLeast('lg')) {
            this.$navbar
                .on('mouseleave', this.hide.bind(this));
        }

        $(window).on('scroll', throttle(this.scroll.bind(this), 30, { trailing: true, leading: true }));


        $(document).on('breakpoints:change', this.resize.bind(this));
        $(document).on('keydown', (e) => {
            if (e.key === 'Escape') {
                this.hide();
            }
        });

        $('body').on('click', () => {
            if (window.breakpoints.atLeast('lg')) {
                this.hide();
            }
        });

        $('body').on('panel:open', this.hide.bind(this));
        // $(window).on('beforeunload', this.hide.bind(this));
    }


    resize() {
        this.hide(true);
        this.headerHeight = $('header.page-header').outerHeight() / 2;
        this.pickMenuFromBreakpoint();
    }

    show() {
        if (!checkBreakpoints()) return;

        if (this.state.open) {
            return;
        }

        clearTimeout(this.hideTimeout);

        this.$header.addClass('nav-open');
        this.$toggler.find('.navbar-toggler').hide();
        this.$toggler.find('.navbar-close').show();

        this.$menu
            .show(0)
            .addClass('show');

        if (!window.breakpoints.atLeast('lg')) {
            // $.show() does not work for vertical menu, needs flex
            this.$menu_vertical.css('display', 'flex');
            this.state.level = 2;
            $('.menu--vertical .submenu').eq(0).css('display', 'flex');
            $('.nav--vertical__rootmenu .nav-link').eq(0).addClass('active');
            // disable page scroll
            $('html').addClass('overflow-hidden');
        }

        $('body').trigger('menu:open', this);

        this.state.open = true;
    }


    buildNavigationRecursively($target) {
        let navigation = [];
        const $parent = $target
            .parent('.nav-item')
            .parents('.nav-item')
            .last();

        const cgid = $target.attr('id');
        navigation.push(cgid);
        if ($parent.length) {
            // Merge navigation array with recursive result through spread
            navigation = [
                ...buildNavigationRecursively($parent.children('.nav-link')),
                ...navigation,
            ];
        }

        return navigation;
    }

    navigate(e) {
        const $target = $(e.currentTarget);

        if (!$target.hasClass('has-submenu') && !window.breakpoints.atLeast('lg')) {
            return;
        }

        e.preventDefault();

        this.state.navigation = this.buildNavigationRecursively($target);
        if (!$target.hasClass('has-submenu')) {
            this.state.level = this.state.level - 1;
        }

        this.show();
    }

    updateNavigation(goingBack = false) {
        const navigation = this.state.navigation;
        this.state.level = navigation.length + 1;

        this.$navlinks.removeClass('active');

        if (navigation.length) {
            let $submenus = $();
            this.state.breadcrumbs = [];
            navigation.forEach((cgid) => {
                const $submenu = this.$menus.find(`[data-cgid="${cgid}"]`);
                const $link = this.$menu.find(`#${cgid}`).first();
                const $links = this.$menus.find(`#${cgid}`);
                $links.addClass('active');
                const title = $link.text().trim();
                const url = $link.attr('href');
                this.state.breadcrumbs.push(`<a href="${url}">${title}</a>`);
                $submenus = $submenus.add($submenu);
            });

            if (goingBack && !window.breakpoints.atLeast('lg')) {
                setTimeout(() => {
                    this.$submenus.not($submenus).hide();
                }, 500);
            } else {
                this.$submenus.not($submenus).hide();
            }

            $submenus.css('display', 'flex');
        } else if (goingBack && !window.breakpoints.atLeast('lg')) {
            setTimeout(() => {
                this.$submenus.hide();
            }, 500);
        } else {
            this.$submenus.hide();
        }

        this.$breadcrumbs.find('#header-breadcrumbs').html(this.state.breadcrumbs.join(this.separator));
        // this.$breadcrumbs.toggle(navigation.length > 0);
        // this.$logo.toggle(navigation.length < 1);
    }

    hide(abrupt = false) {
        if (!this.state.open) {
            this.hideSecondonLevelMenu();
            return;
        }

        if (this.state.lev === 2 && window.breakpoints.atLeast('lg')) {
            this.$header.removeClass('nav-open');
            this.hideSecondonLevelMenu();
            return;
        }

        clearTimeout(this.hideTimeout);

        if (this.state.navigation.length) {
            this.state.navigation = [];
        }

        this.$toggler.find('.navbar-toggler').show();
        this.$toggler.find('.navbar-close').hide();

        this.$menu.addClass('hiding');
        this.$header.removeClass('nav-open');

        if (abrupt === true) {
            this.$menu.removeClass('show hiding');
            $('body').trigger('menu:close', this);
            return;
        }

        if (!window.breakpoints.atLeast('lg')) {
            // enable page scroll
            $('html').removeClass('overflow-hidden');
        }

        if (!window.breakpoints.atLeast(this.hideAnimationBreakpoint)) {
            this.$menu.removeClass('show hiding');
        }

        this.hideTimeout = setTimeout(() => {
            $('body').trigger('menu:close', this);
            this.$menu.removeClass('show hiding');
        }, 500);

        this.state.open = false;
    }

    hideSecondonLevelMenu() {
        this.state.navigation = [];
        // this.$submenus.hide();
        this.$toggler.find('.navbar-toggler').show();
        this.$toggler.find('.navbar-close').hide();
        this.$menu.addClass('hiding');
        this.$header.removeClass('nav-open');
        $('body').trigger('menu:close', this);
        this.$menu.removeClass('show hiding');
        this.state.open = false;
    }

    scroll() {
        if ($(window).scrollTop() > this.headerHeight) {
            if (this.state.isSticky) return;
            this.$header.addClass('sticky');
            $('.search-filter-bar').addClass('sticky').css({
                top: $('.nav').outerHeight() });
            this.state.isSticky = true;
            if (!$('.panel--minicart').hasClass('show')) return;
            $('.panel--minicart').css({
                top: $('.nav').outerHeight(),
            });
        } else {
            if (!this.state.isSticky) return;
            this.state.isSticky = false;
            this.$header.removeClass('sticky');
            $('.search-filter-bar').removeClass('sticky').css('top', 'auto');
            if (!$('.panel--minicart').hasClass('show')) return;
            $('.panel--minicart').css({
                top: $('.nav').outerHeight() + $('.nav').offset().top,
            });
        }

        if ($(window).scrollTop() >= this.headerHeight) {
            $('.search-filter-bar').addClass('sticky').css('top', `${this.headerHeight * 2}px`);
        }

        if (!window.breakpoints.atLeast('sm')) {
            return;
        }
    }
}
