import scrollAnimate from '../components/scrollAnimate';

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
export function clearFormErrors(form) {
    $(form).find('.form-control.is-invalid').removeClass('is-invalid');
}

export default function formValidation(formElement, payload) {
    // clear form validation first
    clearFormErrors(formElement);
    $('.alert', formElement).remove();
    let isFirstInvalidField = true;

    if (typeof payload === 'object' && payload.fields) {
        Object.keys(payload.fields).forEach((key) => {
            if (payload.fields[key]) {
                const feedbackElement = $(formElement).find(`[name="${key}"]`)
                    .parent()
                    .children('.invalid-feedback');

                if (feedbackElement.length > 0) {
                    if (Array.isArray(payload[key])) {
                        feedbackElement.html(payload.fields[key].join('<br/>'));
                    } else {
                        feedbackElement.html(payload.fields[key]);
                    }
                    feedbackElement.siblings('.form-control').addClass('is-invalid');
                }

                // scroll body to first invalid field
                if (isFirstInvalidField) {
                    scrollAnimate($(formElement).find(`[name="${key}"]`), undefined, -90);
                    isFirstInvalidField = false;
                }
            }
        });
    }
    if (payload && payload.error) {
        const form = $(formElement).prop('tagName') === 'FORM'
            ? $(formElement)
            : $(formElement).parents('form');

        form.prepend(`<div class="alert alert-danger" role="alert">${
            payload.error.join('<br/>')}</div>`);
    }
}
