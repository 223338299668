import Panel from 'common/components/panel';
import Login from 'common/login/login';
import Validation from 'common/components/clientSideValidation';

export default class MiniAccount {
    constructor() {
        this.miniaccount = new Panel('.panel--account', { loader: true });
        this.url = this.miniaccount.$panel.data('action');
        this.update = true;
    }

    init() {
        if (!this.miniaccount.$panel.length) {
            return;
        }
        this.events();
    }

    events() {
        $('.page-header .nav').on('click', '.miniaccount-open', (e) => {
            e.preventDefault();
            this.miniaccount.open()
                .then(() => {
                    if (this.update === false) {
                        return;
                    }

                    const self = this;

                    $.ajax({
                        url: this.url,
                        type: 'get',
                        dataType: 'html',
                        success(response) {
                            self.update = false;
                            self.miniaccount.setContent(response);
                            // Add client side validation to injected elements
                            new Validation(self.miniaccount.$panel).invalid();
                            const login = new Login();
                            login.login();
                            login.resetPassword();
                        },
                        error(err) {
                            self.miniaccount.close();
                            console.warn(err);
                        },
                    });
                });
        });

        $('body').on('click', '.miniaccount-close', () => {
            this.miniaccount.close();
        });
    }
}
