/* eslint-disable */
import initPaypalButton from '../../paypal/initCartButton';
import initPaypalBAButton from './registered/initBillingAgreementButton';
import { updateOrderData, returnFromCart } from '../api';


export function initPaypalButtonCart(containerClass) {
    const $cartButton = containerClass ? $(`${containerClass} .js_paypal_button_on_cart_page`) : $('.js_paypal_button_on_cart_page');
    const $paypalImage = $('#paypal_image');
    if ($cartButton.length || $paypalImage.length) {
        const $isCustomerAuthenticated = $cartButton.length && JSON.parse($cartButton.attr('data-paypal-customer-authenticated'));
        const $isBAEnabled = $cartButton.length && JSON.parse($cartButton.attr('data-paypal-ba-enabled'));
        const paypalUrls = $('.js_paypal-content').attr('data-paypal-urls');
        window.paypalUrls = JSON.parse(paypalUrls);
        if (window.paypal && !$paypalImage.length) {
            if ($isCustomerAuthenticated && $isBAEnabled) {
                initPaypalBAButton();
            } else {
                initPaypalButton();
            }
        } else if ($paypalImage.length) {
            const isUpdateRequired = JSON.parse($paypalImage.attr('data-is-update-required'));
            $paypalImage.on('click', isUpdateRequired ? updateOrderData : returnFromCart);
        }
    }
}
export function initFakePaypalButtonCart() {
    const $paypalImage =  $('#paypal_image');
    const $fakePaypalButton = $('#cart-paypal-button-container .fake-button-paypal')
    if ($fakePaypalButton && $paypalImage) {
        $fakePaypalButton.on('click', (e) => {
            $paypalImage.trigger('click');
        });
    }
}
