import {
    showCartErrorHtml,
} from './api';
import { sendExpressCheckout } from '../components/gtmTriggers';

const loaderInstance = require('paypalexpress/loader');

let loader = null;

const defaultStyle = {
    color: 'gold',
    shape: 'rect',
    layout: 'vertical',
    label: 'checkout',
    tagline: false,
};

/**
 *  Gets cart button styles
 *
 * @returns {Object} with button styles or if error appears with default styles
 */
// eslint-disable-next-line consistent-return
function getCartButtonStyle() {
    let cartButtonConfigs;
    try {
        if (document.querySelector('.js_paypal_button_on_cart_page').getAttribute('data-paypal-button-config')) {
            cartButtonConfigs = JSON.parse(document.querySelector('.js_paypal_button_on_cart_page').getAttribute('data-paypal-button-config'));
            return cartButtonConfigs.style;
        }
    } catch (error) {
        return {
            style: defaultStyle,
        };
    }
}

/**
 *  Gets purchase units object, creates order and returns object with data
 *
 * @param {Object} _ - arg
 * @param {Object} actions - paypal actions
 * @returns {Object} with purchase units data and application context
 */
function createOrder(_, actions) {
    loader.show();
    return $.get(`${window.paypalUrls.getCartPurchaseUnit}&productId=${window.paypalProductID}`)
        .then(({ purchase_units }) => {
            const parsedPurchaseUnit = JSON.parse(purchase_units[0].amount.value);
            if (parsedPurchaseUnit === 0) {
                showCartErrorHtml('Order total 0 is not allowed for PayPal');
            }
            // eslint-disable-next-line camelcase
            const application_context = {
                shipping_preference: 'GET_FROM_FILE',
            };
            loader.hide();
            sendExpressCheckout();
            return actions.order.create({
                purchase_units,
                application_context,
            });
        });
}

/**
 *  Makes post call and transfers order ID to returnFromCart endpoint, goes to checkout place order stage
 *
 * @param {Object} orderID - order id
 */
function onApprove({ orderID }) {
    $.spinner().start();
    $.ajax({
        type: 'POST',
        url: window.paypalUrls.returnFromCart,
        contentType: 'application/json',
        data: JSON.stringify({
            paypalOrderID: orderID,
        }),
        success: (data) => {
            loader.hide();
            if (data.returnToShipping) {
                // window.sessionStorage.setItem('errorShippingStage', true);
                window.location.href = window.paypalUrls.shippingStage;
            } else {
                window.location.href = window.paypalUrls.placeOrderStage;
            }
        },
        error: () => {
            loader.hide();
            $.spinner().stop();
            window.location.href = window.paypalUrls.shippingStage;
        },
    });
}

/**
 * Hides loader on paypal widget closing without errors
 *
 */
function onCancel() {
    loader.hide();
}

/**
 * Shows errors if paypal widget was closed with errors
 *
 */
function onError() {
    loader.hide();
    if (!document.querySelector('.cartError')) {
        showCartErrorHtml('An internal server error has occurred. \r\nRetry the request later.');
    }
}

/**
 *Inits paypal button on cart page
 */
function initPaypalButton() {
    const $loaderContainer = document.querySelector('.paypalLoader');
    loader = loaderInstance($loaderContainer);
    loader.show();
    // eslint-disable-next-line new-cap
    window.paypal.Buttons({
        createOrder,
        onApprove,
        onCancel,
        onError,
        style: getCartButtonStyle(),
    }).render('.paypal-cart-button')
        .then(() => {
            loader.hide();
        });
}

export default initPaypalButton;
