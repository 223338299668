export default class CollapsibleItem {
    init() {
        const sizes = ['xs', 'sm', 'md', 'lg', 'xl'];

        sizes.forEach((size) => {
            const selector = `.collapsible-${size} .title`;
            $('body').on('click', selector, (e) => {
                e.preventDefault();
                const $target = $(e.currentTarget);
                const $other = $target
                    .parents(`.collapsible-${size}`)
                    .siblings(`.collapsible-${size}`)
                    .find('.title').not($target);

                this.close($other, size);

                if ($target.parents(`.collapsible-${size}`).hasClass('active')) {
                    this.close($target, size);
                } else {
                    this.open($target, size);
                }
            });
        });
    }

    open($target, size) {
        $target.attr('aria-expanded', true);
        $target.parents(`.collapsible-${size}`).addClass('active');
    }

    close($target, size) {
        $target.attr('aria-expanded', false);
        $target.parents(`.collapsible-${size}`).removeClass('active');
    }
}
