import { findProductInArrayObjects } from 'common/search/search';
import { onShopTheLook, onFormSubmit, onExpressCheckout, onProductClick, onPromoClick, onLoyaltyBanner, onLoyaltyLink, onLoyaltyPopup } from '../seo/gtmEvents';

const EVENTS = {
    shop: 'shop_the_look',
    editorial: 'editorial_popup',
};
const ACTIONS = {
    click: 'click',
    shopNow: 'shop now',
};

export default class GtmTriggers {

    init() {
        this.initFormSubmitTracking();
        this.initShopTheLookTracking();
        this.initAmazonExpressTracking();
        this.initReccomendationTracking();
        this.initLoyaltyTracking();
    }

    initFormSubmitTracking() {
        $('form:not(.login):not(.newsletter)').on('submit', (e) => {
            const formClass = $(e.target).attr('class');
            let loyalty;
            if ($(e.target).find('input[id*=\'loyalty-true\']:checked').length > 0) {
                loyalty = 'loyalty - acconsento';
            }
            if ($(e.target).find('input[id*=\'loyalty-false\']:checked').length > 0) {
                loyalty = 'loyalty -non acconsento';
            }
            onFormSubmit(formClass, loyalty);
        });
    }

    initShopTheLookTracking() {
        $('.shop-the-look').on('click', '.hotspot', (e) => {
            const productID = this.getProductID(e);
            onShopTheLook(EVENTS.shop, ACTIONS.click, productID);
        });

        $('.shop-the-look').on('click', '.add-to-cart', (e) => {
            const productID = $(e.target).closest('.product--grid-tile').data('pid');
            onShopTheLook(EVENTS.shop, ACTIONS.shopNow, productID);
        });

        $('.editorial-popup').on('click', '.hotspot', (e) => {
            const productID = this.getProductID(e);
            onShopTheLook(EVENTS.editorial, ACTIONS.click, productID);
        });

        $('.editorial-popup').on('click', '.add-to-cart', (e) => {
            const productID = $(e.target).closest('.product--grid-tile').data('pid');
            onShopTheLook(EVENTS.editorial, ACTIONS.shopNow, productID);
        });
    }

    initAmazonExpressTracking() {
        $(document).on('click', '.amazonpay-wrapper', (e) => {
            const pid = `${this.getCartItems()}|${$('.product-id').html()}`;
            const action = `${$(e.target).attr('class').includes('paypal') ? 'paypal' : 'amazon'}_pdp`;
            onExpressCheckout(action, pid);
        });

        $(document).on('click', '.amazon-container', (e) => {
            const pid = this.getCartItems();
            const action = `${$(e.target).attr('class').includes('paypal') ? 'paypal' : 'amazon'}_${$(e.target).closest('.cart-page').length > 0 ? 'cart' : 'minicart'}`;
            onExpressCheckout(action, pid);
        });
    }

    initReccomendationTracking() {
        $('.recently-viewed').on('click', '.pdp-link__anchor', (e) => {
            this.sendRecommendationEvent(e, window.recommendationPromotions, window.recommendationProducts);
        });
        $('.recommendations').on('click', '.pdp-link__anchor:not(.recom .pdp-link__anchor)', (e) => {
            this.sendRecommendationEvent(e, window.recommendationPromotions, window.recommendationProducts);
        });
        $('.recommendations').on('click', '.recom .pdp-link__anchor', (e) => {
            this.sendRecommendationEvent(e, window.recommendationLookPromotions, window.recommendationLookProducts);
        });
        $('.recommendation').on('click', '.pdp-link__anchor', (e) => {
            this.sendRecommendationEvent(e, window.recommendationLookPromotions, window.recommendationLookProducts);
        });
        $(document).on('click', '.suggestions__product .pdp-link__anchor:not(.suggestions__product-recommendations .pdp-link__anchor)', (e) => {
            this.sendRecommendationEvent(e, window.gtmSuggestionsPromotions, window.gtmSuggestionsProducts);
        });
        $(document).on('click', '.suggestions__product-recommendations .pdp-link__anchor', (e) => {
            this.sendRecommendationEvent(e, window.gtmSuggestionsRecommPromotions, window.gtmSuggestionsRecommProducts);
        });
    }

    initLoyaltyTracking() {
        $(document).on('click', '.loyalty-banner a', (e) => {
            const lable = $(e.target).find('span').text() || $(e.target).text();
            onLoyaltyLink('banner_link', lable);
        });

        $(document).on('click', '.vip-sale-banner a, .loyalty-hero a', (e) => {
            const lable = $(e.target).find('span').text() || $(e.target).text();
            const position = $(document).height() / 2 > $(e.target).offset().top ? 'upper' : 'lower';
            const ctaNumber = `${$(e.target).closest('section').find('a').length > 1 ? 'double' : 'single'} cta`;
            onLoyaltyBanner(`${position} banner ${ctaNumber}`, lable);
        });

        $(document).on('click', '.experience-assets-loyalty_landing_card a', (e) => {
            const lable = $(e.target).find('span').text() || $(e.target).text();
            onLoyaltyLink('guideline_card_link', lable);
        });

        $('#loyaltyModal').on('shown.bs.modal', () => {
            onLoyaltyPopup('impression', '');
        });

        $('#loyaltyModal').on('click', '.close', () => {
            onLoyaltyPopup('close', '');
        });

        $('#loyaltyModal').on('click', 'a', (e) => {
            const lable = $(e.target).find('span').text() || $(e.target).text();
            onLoyaltyPopup('close', lable);
        });
    }

    getProductID(element) {
        const elementID = $(element.target).closest('.hotspot-container').data('target');
        const productID = $(elementID).find('.product--grid-tile').data('pid');
        return productID;
    }

    getCartItems() {
        return window.lineItemCtnrProducts[0].map(item => item.item_id).join('|');
    }

    sendRecommendationEvent(e, recommandetionPromotions, recommandetionProducts) {
        const currentProductUrl = e.target.href || e.target.parentNode.href;
        const productObject = findProductInArrayObjects(currentProductUrl, recommandetionProducts);
        const promoObject = findProductInArrayObjects(currentProductUrl, recommandetionPromotions);
        if (Object.keys(promoObject).length !== 0) {
            onPromoClick([promoObject]);
        }
        onProductClick([productObject], currentProductUrl);
    }
}

export function sendExpressCheckout() {
    const items = window.paypalProductID ? window.paypalProductID : window.lineItemCtnrProducts[0].map(item => item.item_id).join('|');
    let page = 'pdp';
    const isCart = $('.cart-page').length > 0;
    const isMiniCart = $('.minicart').length > 0;
    if (isCart && !isMiniCart) {
        page = 'cart';
    } else if (!window.paypalProductID && isMiniCart) {
        page = 'minicart';
    }

    onExpressCheckout(`paypal_${page}`, items);
}
