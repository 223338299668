export default class WishlistHeart {
    init() {
        this.addToWishlist();
    }

    addToWishlist() {
        $('body').on('click', '.add-to-wishlist', function (e) {
            e.preventDefault();
            const $link = $(this);
            const url = $(this).attr('href') || $(this).data('href');
            let pid = $(this).closest('.product').data('pid');

            // In PDP, the pid is in a different place..
            if (!pid) {
                pid = $(this).closest('.product-detail').find('.product-id').html();
            }

            if (!url || !pid) {
                return;
            }

            $.spinner().start();
            $.ajax({
                url,
                type: 'post',
                dataType: 'json',
                data: {
                    pid,
                    optionId: null,
                    optionVal: null,
                },
                success(data) {
                    if (data.success) {
                        if (data.success) {
                            $link.toggleClass('active');
                        }
                    }
                },
                complete() {
                    $.spinner().stop();
                    $(document).trigger('wishlist:afterAddToList');
                },
            });
        });
    }
}
