import { onAddToCart, onProductAction } from 'common/seo/gtmEvents';
import MiniWishlist from './miniWishlist';

export default class WishlistSizeSelector {
    constructor() {
        this.el = '.card--wishlist';
        this.$el = $(this.el);
        this.$addToCart = $('.add-to-cart-from-wishlist', this.$el);
        this.$wishlistProductCardSizeBtn = $('.wishlist-size');
        this.$sizeSelected = null;
    }

    init() {
        this.events();
    }

    events() {
        this.$addToCart.on('click', this.onClickAddToCart.bind(this));
        this.$wishlistProductCardSizeBtn.on('change', this.onSelectSize.bind(this));
    }

    /**
     * Handles add to cart button click
     */
    onClickAddToCart(e) {
        const $addToCartButton = $(e.currentTarget);
        const addToCartUrl = $addToCartButton.data('url');
        const pid = $addToCartButton.attr('data-pid');

        const payload = {
            pid,
            quantity: 1,
        };

        if (!(pid && addToCartUrl)) {
            return;
        }

        $addToCartButton.prop('disabled', true);

        $.spinner().start();

        $(document.body).trigger('product:beforeAddToCart', $addToCartButton);

        $.ajax({
            url: addToCartUrl,
            method: 'POST',
            data: payload,
            success: (res) => {
                if (res.error) {
                    return;
                }
                $('.minicart-quantity').empty().append(res.quantityTotal).show();
                $(document.body).trigger('product:afterAddToCart', res);
                const productObject = res.pdpProducts;
                // @todo give the possibility to add more quantity
                if (productObject.length > 0 && !res.error) {
                    productObject[0].quantity = payload.quantity;
                    productObject[0].item_list_id = $(e.target).closest('.panel--wishlist').length > 0 ? 'MiniWishlist' : 'Wishlist';
                    productObject[0].item_list_name = $(e.target).closest('.panel--wishlist').length > 0 ? 'MiniWishlist' : 'Wishlist';
                    onAddToCart(window.currency, productObject);
                    productObject[0].item_list_id = 'MiniCart';
                    productObject[0].item_list_name = 'MiniCart';
                    window.lineItemCtnrProducts[0].push(productObject[0]);
                }
            },
            complete: () => {
                $.spinner().stop();
                $addToCartButton.prop('disabled', false);
            },
        });
    }

    /**
     * Handles size selection
     */
    onSelectSize(e) {
        const $sizeClicked = $(e.currentTarget);
        const $container = $(e.currentTarget).parents('.card--wishlist');
        const selectedValueUrl = $sizeClicked.find(':selected').data('url');
        const wishlistChangeProductUrl = $sizeClicked.find(':selected').data('wishlistchangeproducturl');
        const selectedUuid = $(e.currentTarget).data('uuid');

        if (this.$sizeSelected && this.$sizeSelected === $sizeClicked.val()) {
            return;
        }

        $.spinner().start();
        $(document.body).trigger('product:beforeAttributeSelect', { url: selectedValueUrl, container: $container });

        if (wishlistChangeProductUrl && selectedUuid) { // ADD HERE THE NEW AJAX FOR THE WISHLIST CHANGE PRODUCT URL
            const currentPage = $('.page').length && $('.page').data('action');
            const wishlistChangeurl = `${wishlistChangeProductUrl}&uuid=${selectedUuid}&action=${currentPage}`;
            const self = this;
            $('.wishlist-account-card').spinner().start();
            $.ajax({
                url: wishlistChangeurl,
                type: 'GET',
                dataType: 'html',
                success(html) {
                    $('.dashboard-account__section--wishlist--cards').html(html);
                    $('.wishlist-size').on('change', self.onSelectSize.bind(self));
                    const selectedValue = $sizeClicked.find(':selected').data('attr-value');
                    const productId = $sizeClicked.find(':selected').data('product-id');
                    onProductAction(`${productId} - ${selectedValue}`, 'sizeWislist');
                    // Seems unused trigger for the wishlist
                    // $(document.body).trigger('product:afterAttributeSelect', { html, url: selectedValueUrl, container: $container });
                },
                error(jqXHR, textStatus, errorThrown) {
                    console.error('Error on changing product:', textStatus, errorThrown);
                },
                complete: () => {
                    const miniWishlist = new MiniWishlist();
                    miniWishlist.updateContent();
                    $.spinner().stop();
                },
            });
        }
    }
}
