import {
    getBillingAgreementToken,
    createBillingAgreementCall,
    showCartErrorHtml,
} from '../../api';

const loaderInstance = require('paypalexpress/loader');

let loader = null;

/**
 *  Create's Billing Agreement
 *
 * @returns {string} returns JSON response that includes an data token
 */
function createBillingAgreement() {
    loader.show();
    const isCartFlow = true;
    return getBillingAgreementToken(isCartFlow)
        .then((data) => data.token)
        .fail(() => {
            loader.hide();
        });
}

/**
 *  Makes post call and transfers billingToken to returnFromCart endpoint, triggers checkout (stage = place order)
 *
 * @param {string} billingToken - billing agreement token
 * @returns {Object} JSON response that includes the billing agreement ID and information about the payer
 */
function onApprove({ billingToken }) {
    $.spinner().start();
    return createBillingAgreementCall(billingToken)
        .then(({ id, payer }) => {
            const payerEmail = payer.payer_info.email;
            const billingAgreementId = id;
            return $.ajax({
                type: 'POST',
                url: window.paypalUrls.returnFromCart,
                data: JSON.stringify({ billingAgreementId, payerEmail }),
                contentType: 'application/json',
                dataType: 'json',
            });
        })
        .then((data) => {
            loader.hide();
            if (data.returnToShipping) {
                // window.sessionStorage.setItem('errorShippingStage', true);
                window.location.href = window.paypalUrls.shippingStage;
            } else {
                window.location.href = window.paypalUrls.placeOrderStage;
            }
        })
        .fail(() => {
            loader.hide();
            $.spinner().stop();
            window.location.href = window.paypalUrls.shippingStage;
        });
}

/**
 * Hides loader on paypal widget closing without errors
 *
 */
function onCancel() {
    loader.hide();
}

/**
 * Shows errors if paypal widget was closed with errors
 *
 */
function onError() {
    loader.hide();
    showCartErrorHtml('An internal server error has occurred. \r\nRetry the request later.');
}

/**
 *Inits paypal Billing Agreement button on billing checkout page
 */
function initPaypalBAButton() {
    const $loaderContainer = document.querySelector('.paypalLoader');
    loader = loaderInstance($loaderContainer);
    loader.show();
    // eslint-disable-next-line new-cap
    window.paypal.Buttons({
        createBillingAgreement,
        onApprove,
        onCancel,
        onError,
    }).render('.paypal-cart-button')
        .then(() => {
            loader.hide();
        });
}

export default initPaypalBAButton;
