/**
 * Renders a modal window that will track the users consenting to accepting site tracking policy
 */

export function setConsent(url) {
    return $.ajax({
        url,
        type: 'get',
        dataType: 'json',
    });
}

export function setCookieDataLayer() {
    if (window && window.dataLayer && window.dataLayer.length > 0) {
        const globalDL = window.dataLayer.filter(dl => Object.hasOwnProperty.call(dl, 'event') && dl.event === 'DL')[0];
        if (globalDL) {
            globalDL.cookie = 'OK';
        }
    }
}

export function showConsentModal() {
    if (!$('.tracking-consent').data('caonline')) {
        return;
    }

    const urlContent = $('.tracking-consent').data('url');
    const urlAccept = $('.tracking-consent').data('accept');
    const textYes = $('.tracking-consent').data('accepttext');
    const textHeader = $('.tracking-consent').data('heading');

    const htmlString = `${'<!-- Modal -->'
        + '<div class="modal show" id="consent-tracking" role="dialog" tabindex="-1" aria-labelledby="consent-tracking-header" style="display: block;">'
        + '<div class="modal-dialog m-0">'
        + '<!-- Modal content-->'
        + '<div class="modal-content border-0">'
        + '<div class="modal-header sr-only" id="consent-tracking-header">'}${
            textHeader
        }</div>`
        + '<div class="container">'
        + '<div class="row">'
        + '<div class="modal-body col col-12 col-md-11 pt-3 pt-md-2 pb-1 pb-md-2 mt-md-1 mb-md-1"></div>'
        + '<div class="modal-footer col col-12 col-md-1 border-0 justify-content-center justify-content-md-end pt-3 pt-md-0 pb-4 pb-md-0">'
        + '<div class="button-wrapper">'
        + `<button class="affirm btn btn-link btn-link--primary mb-0" data-url="${urlAccept}">${
            textYes
        }</button>`
        + '</div>'
        + '</div>'
        + '</div>'
        + '</div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);

    $.ajax({
        url: urlContent,
        type: 'get',
        dataType: 'html',
        async: false,
        success(response) {
            $('#consent-tracking').find('.modal-body').html(response);
        },
        error() {
            $('#consent-tracking').remove();
        },
    });

    $('#consent-tracking .button-wrapper button').click(function (e) {
        e.preventDefault();
        const url = $(this).data('url');
        setConsent(url).done(() => {
            $('#consent-tracking').remove();
            $('body').trigger('consentTracking:check');
            $.spinner().stop();
            setCookieDataLayer();
        }).fail(() => {
            $('#consent-tracking').remove();
            $.spinner().stop();
        });
    });

    $('.page,.nav').one('click', (e) => {
        const legalPage = $('.legal-page');
        if ($('#consent-tracking .button-wrapper button').length > 0 && legalPage.length === 0) {
            e.preventDefault();
            const url = $('#consent-tracking .button-wrapper button').data('url');
            setConsent(url).done(() => {
                $('#consent-tracking').remove();
                $('body').trigger('consentTracking:check');
                $.spinner().stop();
                $(e.target)[0].click();
                setCookieDataLayer();
            }).fail(() => {
                $('#consent-tracking').remove();
                $.spinner().stop();
                $(e.target)[0].trigger('click');
            });
        }
    });
}

export default class ConsentTracking {
    init() {
        if ($('.consented').length === 0 && $('.standard-consent-tracking-enabled').length === 1 && $('.tracking-consent').hasClass('api-true')) {
            showConsentModal();
        }

        if ($('.tracking-consent').hasClass('api-true')) {
            $('.tracking-consent').click(() => {
                showConsentModal();
            });
        }
    }
}
