import CommonSearchBar from 'common/components/searchBar';
import { onProductsImpression, onPromosImpression } from 'common/seo/gtmEvents';
import { throttle } from 'lodash';

export default class SearchBar extends CommonSearchBar {

    events() {
        $('body')
        .on('menu:open', (e, clickedElement) => {
            this.searchbar.close();

            if (this.menuDisableScroll) {
                if (window.breakpoints.atLeast('sm') && !clickedElement.isMenuOpen) {
                    $('body').trigger('searchbar:enablescroll');
                }
            } else if (!clickedElement.isMenuOpen) {
                $('body').trigger('searchbar:enablescroll');
            }
        })
        .on('panel:open', (e, clickedElement) => {
            if (clickedElement === this.searchbar) {
                $('html').addClass('disableScroll');
                return;
            }
            this.searchbar.close();

            if (!clickedElement.isMenuOpen) {
                $('body').trigger('searchbar:enablescroll');
            }
        })
        .on('panel:close', (e, clickedElement) => {
            if (!clickedElement.isMenuOpen) {
                $('html').removeClass('disableScroll');
                $('body').trigger('searchbar:enablescroll');
            }
        });

        $(document)
            .on('breakpoints:change', () => {
                this.searchbar.close();
            });

        $('.page-header .nav').on('click', '.searchbar-open', (e) => {
            e.preventDefault();
            this.setTopPosition();

            this.searchbar.open().then(() => {
                // If search field is empty, focus on in when panel opens
                const $search = this.searchbar.$panel.find('input.search-field');
                if ($search.val() === '') {
                    $search.focus();
                }
            });
            this.pushSearchRecommendations();
            $('body').trigger('searchbar:disablescroll');
        });

        $('body').on('click', '.reset-button', (e) => {
            e.preventDefault();
            this.searchbar.close();
        });
    }

    setTopPosition() {
        const headerHeight = Math.round($('.navbar').outerHeight());
        let headerDelta = 0;
        if (this.$preHeader) {
            if (this.$preHeader.isInViewport()) {
                headerDelta = Math.round(this.$preHeader.outerHeight());
            }
        }
        if (window.breakpoints.atLeast('md')) {
            this.$search.css('top', headerHeight + headerDelta);
        } else this.$search.css('top', 0);
    }

    hide() {
        $(window).on('scroll', throttle(() => {
            if (this.$search.hasClass('show')) {
                this.setTopPosition();
            }

            if ($(window).scrollTop() > this.$search.offset().top + this.$search.innerHeight()) this.searchbar.close();
        }, 30));
    }

    pushSearchRecommendations() {
        const $searchOption = $('.search-options_radio input:checked');
        const gtmData = $('.suggestions__product-recommendations').find(`[data-filter='${$searchOption.val()}']`).find('.recommendations').data('gtm');
        window.gtmSuggestionsRecommPromotions = gtmData && gtmData.gtmPromotionsArray ? JSON.parse(gtmData.gtmPromotionsArray) : null;
        window.gtmSuggestionsRecommProducts = gtmData && gtmData.gtmProductsArray ? JSON.parse(gtmData.gtmProductsArray) : null;
        onProductsImpression([gtmData && gtmData.gtmProductsArray ? JSON.parse(gtmData.gtmProductsArray) : []], window.currency);
        onPromosImpression([gtmData && gtmData.gtmPromotionsArray ? JSON.parse(gtmData.gtmPromotionsArray) : []]);
    }
}
