/**
 * Get cookie value by cookie name from browser
 * @param {string} cookieName - name of the cookie
 * @returns {string} cookie value of the found cookie name
 */
export function getCookie(cookieName) {
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        let cookieItem = cookieArray[i];
        while (cookieItem.charAt(0) === ' ') {
            cookieItem = cookieItem.substring(1);
        }
        if (cookieItem.indexOf(name) === 0) {
            return cookieItem.substring(name.length, cookieItem.length);
        }
    }
    return '';
}

export default class Cookie {
    init() {
        if ($('.valid-cookie-warning').length > 0) {
            let previousSessionID = window.localStorage.getItem('previousSid');
            const currentSessionID = getCookie('sid');
            if (!previousSessionID && currentSessionID) {
                // When a user first time visit the home page,
                // set the previousSessionID to currentSessionID
                // and Show the cookie alert
                previousSessionID = currentSessionID;
                window.localStorage.setItem('previousSid', previousSessionID);
                $('.cookie-warning-messaging').show();
            } else if (previousSessionID && previousSessionID === currentSessionID) {
                // Hide the cookie alert if user is in the same session
                $('.cookie-warning-messaging').hide();
            } else {
                // Clear the previousSessionID from localStorage
                // when user session is changed or expired
                window.localStorage.removeItem('previousSid');
            }
        }
    }
}
