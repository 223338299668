import Resource from 'common/util/Resource';
import { getPidValue } from '../base';

export default class amazonPayButtonPDP {
    constructor() {
        this.$containerPDP = $('.product-detail');
        this.$buttonAmazonPay = this.$containerPDP.find('.amazonpay-wrapper #AmazonPayButtonPDP');
        this.$labelPayment = $('.product-detail').find('.label-payment');
    }
    initbutton() {
        return new Promise((resolve) => {
            $(document).ready(() => {
                /* eslint-disable */
                if ($('#AmazonPayButtonPDP').length) {
                    amazon.Pay.renderButton('#AmazonPayButtonPDP', {
                        merchantId: AmazonSitePreferences.AMAZON_MERCHANT_ID,
                        createCheckoutSession: {
                            url: AmazonURLs.createCheckoutSession + '?productId=' + this.$buttonAmazonPay.attr('sku')
                            // url: AmazonURLs.createCheckoutSession + '?productId=WF0302T45872222246'
                        },
                        ledgerCurrency: AmazonSitePreferences.AMAZON_CURRENCY,
                        checkoutLanguage: AmazonSitePreferences.AMAZON_CHECKOUT_LANGUAGE,
                        productType: AmazonSitePreferences.AMAZON_PRODUCT_TYPE,
                        sandbox: AmazonSitePreferences.AMAZON_SANDBOX_MODE,
                        placement: 'Cart',
                        buttonColor: 'LightGray',
                    });
                    this.hide();
                    window.onload = () => {
                        this.loadStyle();
                    }
                }
                /* eslint-enable */
            });
            setTimeout(() => {
                resolve();
            }, 300);
        });
    }

    loadStyle(param) {
        const $target = param || 'AmazonPayButtonPDP';
        const style = document.createElement('style');
        const stylePrefAmazon = Resource.getSitePreference('customAmazonStyle', 'amazonButtonCustomStyle');
        style.innerHTML = stylePrefAmazon ? stylePrefAmazon.toString() : '';
        const amazonPayButton = document.getElementById($target);
        amazonPayButton.shadowRoot.appendChild(style);
    }
    init(pid) {
        if (this.$buttonAmazonPay && this.$buttonAmazonPay.length) {
            if (!pid) {
                const updatedPid = $('.product-id').html();
                this.updateProductID(updatedPid || null);
            } else {
                this.updateProductID(pid || null);
            }
        } else {
            return;
        }
    }
    show() {
        if ($('.amazonpay-wrapper').hasClass('d-none')) {
            $('.amazonpay-wrapper').removeClass('d-none');
            this.$labelPayment.removeClass('d-none');
        }
    }
    hide() {
        if (!$('.amazonpay-wrapper').hasClass('d-none')) {
            $('.amazonpay-wrapper').addClass('d-none');
            this.$labelPayment.addClass('d-none');
        }
    }
    updateProductID(pid) {
        const buttonAddToCart = $('.product-detail .main-details').find('button.add-to-cart, button.add-to-cart-global');
        let productPid = null;
        if (pid) {
            productPid = pid;
        } else {
            productPid = getPidValue(buttonAddToCart);
        }
        if (productPid && !buttonAddToCart.is(':disabled') && !buttonAddToCart.hasClass('not-ready')) {
            const newDiv = `${'<div id="AmazonPayButtonPDP"></div>'}`;
            this.$containerPDP.find('.amazonpay-wrapper #AmazonPayButtonPDP').replaceWith(newDiv);
            this.$buttonAmazonPay.attr('sku', productPid);
            this.initbutton()
                .then(() => {
                    this.loadStyle();
                    this.show();
                });
        } else {
            this.hide();
        }
    }
}
