import { throttle } from 'lodash';
import { checkBreakpoints } from './breakpoints';

export function buildNavigationRecursively($target) {
    let navigation = [];
    const $parent = $target
        .parent('.nav-item')
        .parents('.nav-item')
        .first();

    const cgid = $target.attr('id');
    navigation.push(cgid);

    if ($parent.length) {
        // Merge navigation array with recursive result through spread
        navigation = [
            ...buildNavigationRecursively($parent.children('.nav-link')),
            ...navigation,
        ];
    }

    return navigation;
}

/**
 * Navigation is handled across devices through setting the state.navigation
 * array property on the class, as an ordered list of CGID representing the
 * current selected path of the user. Through a SETTER, the correct UI is then
 * rendered solely based on the navigation array. This allows for manipulating a simple array
 * to handle all navigation operations even programmatically.
 */
export default class Menu {
    constructor() {
        const self = this;
        this.hideAnimationBreakpoint = 'sd';
        this.$header = $('.page-header');
        this.$navbar = this.$header.find('.nav');
        this.$menus = this.$navbar.find('.nav--vertical, .nav--horizontal, .menu--root');
        this.$toggler = this.$navbar.find('.navbar__toggler');
        this.$menu = this.$navbar.find('.nav--vertical'); // re-assign on resize
        this.$menu_vertical = this.$navbar.find('.nav--vertical');
        this.$navlinks = this.$menus.find('.nav-link');

        this.$logo = this.$navbar.find('.nav--vertical__header__logo');
        this.$breadcrumbs = this.$navbar.find('.nav--vertical__header__breadcrumbs');
        this.$submenus = this.$navbar.find('.submenu');
        this.separator = '<span class="sep" aria-hidden="true">/</span>';
        this.headerDelta = Math.round($('.pre-header').outerHeight() || 0);
        this.toggleEventName = 'click';
        this.defaultNavigation = [];
        this.stickyTimeout = 350;
        this.state = {
            open: false,
            breadcrumbs: [],
            nav: [],
            lev: 0,
            sticky: false,
            set navigation(next) {
                const prev = this.nav;
                this.nav = next;
                self.updateNavigation(next.length < prev.length);
            },
            get navigation() {
                return this.nav;
            },
            set level(index) {
                self.$navbar
                    .add(self.$header)
                    .attr('data-level', index)
                    .data('level', index);
                this.lev = index;
            },
            get level() {
                return this.lev;
            },
            set isSticky(value) {
                if (value === this.sticky) {
                    return;
                }

                // if sticky value changes, trigger resize after animation
                this.sticky = value;
                setTimeout(() => {
                    $(window).trigger('resize');
                }, this.stickyTimeout);
            },
            get isSticky() {
                return this.sticky;
            },
        };
    }

    init() {
        if (!checkBreakpoints()) return;

        if (!this.$navbar.length || !this.$menu.length) {
            return;
        }

        this.events();
        this.pickMenuFromBreakpoint();
        this.scroll();
    }

    pickMenuFromBreakpoint() {
        if (window.breakpoints.atLeast('sm')) {
            this.$menu = this.$navbar.find('.nav--horizontal');
        } else {
            this.$menu = this.$navbar.find('.nav--vertical');
        }
    }

    events() {
        this.$navbar
            .on('click', this.stopPropagation.bind(this))
            .on('click', '.navbar-toggler', this.show.bind(this))
            .on('click', '.navbar-close', this.hide.bind(this))
            .on('click', '.nav--vertical__header__breadcrumbs', this.back.bind(this))
            .on(this.toggleEventName, '.nav-link', this.navigate.bind(this));

        $(document).on('breakpoints:change', this.resize.bind(this));
        $('body').on('click', this.hide.bind(this));
        $(window).on('scroll', throttle(this.scroll.bind(this), 30));
        $(document).on('keydown', (e) => {
            if (e.key === 'Escape') {
                this.hide();
            }
        });
        $('body').on('panel:open', this.hide.bind(this));
    }

    stopPropagation(e) {
        e.stopPropagation();
    }

    show() {
        if (!checkBreakpoints()) return;

        if (this.state.open) {
            return;
        }

        clearTimeout(this.hideTimeout);

        this.$header.addClass('nav-open');
        this.$toggler.find('.navbar-toggler').hide();
        this.$toggler.find('.navbar-close').show();

        this.$menu
            .show(0)
            .addClass('show');

        if (window.breakpoints.atLeast('xs')) {
            // $.show() does not work for vertical menu, needs flex
            this.$menu_vertical.css('display', 'flex');
        }

        $('body').trigger('menu:open', this);

        this.state.open = true;
    }

    hide(abrupt = false) {
        if (!this.state.open) {
            return;
        }

        clearTimeout(this.hideTimeout);

        if (this.state.navigation.length) {
            this.state.navigation = this.defaultNavigation;
        }

        this.$toggler.find('.navbar-toggler').show();
        this.$toggler.find('.navbar-close').hide();

        this.$menu.addClass('hiding');
        this.$header.removeClass('nav-open');

        if (abrupt === true) {
            this.$menu.hide().removeClass('show hiding');
            $('body').trigger('menu:close', this);
            this.state.open = false;
            return;
        }

        if (!window.breakpoints.atLeast(this.hideAnimationBreakpoint)) {
            this.$menu.removeClass('show hiding');
        }

        this.hideTimeout = setTimeout(() => {
            $('body').trigger('menu:close', this);
            this.$menu.hide().removeClass('show hiding');
            this.state.open = false;
        }, 500);
    }

    resize() {
        this.hide(true);

        this.pickMenuFromBreakpoint();
    }

    back(e) {
        if (this.state.navigation.length > 0) {
            this.state.navigation = this.state.navigation.slice(0, -1);
        }

        if (e) {
            e.preventDefault();
        }
    }

    navigate(e) {
        const $target = $(e.currentTarget);

        if ($target.hasClass('has-submenu')) {
            e.preventDefault();
            this.state.navigation = buildNavigationRecursively($target);
            this.show();
        } else {
            this.state.navigation = this.defaultNavigation;
            this.hide();
        }

        // For small devices and up, auto-open first sublevel
        if (window.breakpoints.atLeast('sm') && this.state.navigation.length === 1) {
            const levelOne = this.state.navigation[0];
            const $submenu = this.$menus.find(`[data-cgid="${levelOne}"]`);
            const levelTwo = $submenu.find('.nav-link.has-submenu').first().attr('id');

            if (levelOne && levelTwo) {
                this.state.navigation = [levelOne, levelTwo];
            }
        }
    }

    updateNavigation(goingBack = false) {
        const navigation = this.state.navigation;
        this.state.level = navigation.length + 1;

        this.$navlinks.removeClass('active');

        if (navigation.length) {
            let $submenus = $();
            this.state.breadcrumbs = [];
            navigation.forEach((cgid) => {
                const $submenu = this.$menus.find(`[data-cgid="${cgid}"]`);
                const $link = this.$menu.find(`#${cgid}`).first();
                const $links = this.$menus.find(`#${cgid}`);
                $links.addClass('active');
                const title = $link.text().trim();
                const url = $link.attr('href');
                this.state.breadcrumbs.push(`<a href="${url}">${title}</a>`);
                $submenus = $submenus.add($submenu);
            });

            if (goingBack && !window.breakpoints.atLeast('sm')) {
                setTimeout(() => {
                    this.$submenus.not($submenus).hide();
                }, 500);
            } else {
                this.$submenus.not($submenus).hide();
            }

            $submenus.show();
        } else if (goingBack && !window.breakpoints.atLeast('sm')) {
            setTimeout(() => {
                this.$submenus.hide();
            }, 500);
        } else {
            this.$submenus.hide();
        }

        this.$breadcrumbs.find('#header-breadcrumbs').html(this.state.breadcrumbs.join(this.separator));
        this.$breadcrumbs.toggle(navigation.length > 0);
        this.$logo.toggle(navigation.length < 1);
    }

    scroll() {
        if ($(window).scrollTop() > this.headerDelta) {
            this.$header.addClass('sticky');
            this.state.isSticky = true;
        } else {
            this.$header.removeClass('sticky');
            this.state.isSticky = false;
        }

        if (!window.breakpoints.atLeast('sm')) {
            return;
        }

        this.hide();
    }
}
