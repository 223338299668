import Panel from 'common/components/panel';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { throttle } from 'lodash';
import { checkBreakpoints } from './breakpoints';

class SearchPanel extends Panel {
    constructor(selector, options) {
        super(selector, options);

        this.searchHasResults = false;
    }
}

export default class SearchBar {
    constructor(searchDisableScroll = false, menuDisableScroll = false) {
        this.searchbar = new SearchPanel('.panel--site-search');
        this.update = true;
        this.$search = $('.panel--site-search');
        this.$preHeader = $('.pre-header');
        this.targetElement = document.querySelector('.site-search.panel');
        this.searchDisableScroll = searchDisableScroll;
        this.menuDisableScroll = menuDisableScroll;
    }

    init() {
        if (!checkBreakpoints()) return;

        if (!this.searchbar.$panel.length) {
            return;
        }

        this.events();
        this.hide();
        this.handleSearchScroll();
    }

    events() {
        $('body')
        .on('menu:open', (e, clickedElement) => {
            this.searchbar.close();

            if (this.menuDisableScroll) {
                if (window.breakpoints.atLeast('sm') && !clickedElement.isMenuOpen) {
                    $('body').trigger('searchbar:enablescroll');
                }
            } else if (!clickedElement.isMenuOpen) {
                $('body').trigger('searchbar:enablescroll');
            }
        })
        .on('panel:open', (e, clickedElement) => {
            if (clickedElement === this.searchbar) {
                return;
            }
            this.searchbar.close();

            if (!clickedElement.isMenuOpen) {
                $('body').trigger('searchbar:enablescroll');
            }
        })
        .on('panel:close', (e, clickedElement) => {
            if (!clickedElement.isMenuOpen) {
                $('body').trigger('searchbar:enablescroll');
            }
        });

        $(document)
            .on('breakpoints:change', () => {
                this.searchbar.close();
            });

        $('.page-header .nav').on('click', '.searchbar-open', (e) => {
            e.preventDefault();
            this.setTopPosition();

            this.searchbar.open().then(() => {
                // If search field is empty, focus on in when panel opens
                const $search = this.searchbar.$panel.find('input.search-field');
                if ($search.val() === '') {
                    $search.focus();
                }
            });

            $('body').trigger('searchbar:disablescroll');
        });

        $('body').on('click', '.reset-button', (e) => {
            e.preventDefault();
            this.searchbar.close();
        });
    }

    hide() {
        $(window).on('scroll', throttle(() => {
            if (this.$search.hasClass('show')) {
                this.setTopPosition();
            }

            if (window.breakpoints.atLeast('sm')) {
                this.searchbar.close();
            }
        }, 30));
    }

    setTopPosition() {
        const headerHeight = Math.round($('.navbar').outerHeight());
        let headerDelta = 0;
        if (this.$preHeader) {
            if (this.$preHeader.isInViewport()) {
                headerDelta = Math.round(this.$preHeader.outerHeight());
            }
        }
        this.$search.css('top', headerHeight + headerDelta);
    }

    handleSearchScroll() {
        $('body').on('searchbar:hasresults', () => {
            this.searchbar.searchHasResults = true;
        });

        $('body').on('searchbar:hasnotresults', () => {
            this.searchbar.searchHasResults = false;
        });

        $('body').on('searchbar:enablescroll', () => {
            if (this.searchDisableScroll) {
                $('html').removeClass('disableScroll');
                enableBodyScroll(this.targetElement);
            }
        });

        $('body').on('searchbar:disablescroll', () => {
            if (this.searchDisableScroll && this.searchbar.searchHasResults) {
                $('html').addClass('disableScroll');
                disableBodyScroll(this.targetElement);
            }
        });
    }
}
