import '@babel/polyfill';

import Breakpoints from 'common/components/breakpoints';
import Spinner from 'common/components/spinner';
import Cookie from 'common/components/cookie';
import ConsentTracking from 'common/components/consentTracking';
import MiniAccount from 'common/components/miniAccount';
import Newsletter from 'common/components/newsletter';
import CollapsibleItem from 'common/components/collapsibleItem';
import Validation from 'common/components/clientSideValidation';
import CountrySelector from 'common/components/countrySelector';
import Tooltip from 'common/components/toolTip';
import Buttons from 'common/components/buttons';
import Select from 'common/components/select';
import Resource from 'common/util/Resource';
import MultipleText from 'common/components/multipleText';
import CountryPopup from 'common/components/countryPopup';
import FormFrame from 'common/components/formFrame';
import GtmTriggers from 'common/components/gtmTriggers';
import MiniWishlist from './components/miniWishlist';
import WishlistHeart from './product/wishlistHeart';
import SearchBar from './components/searchBar';
import Search from './components/search';
import MiniCart from './components/miniCart';
import Menu from './components/menu';
import Footer from './components/footer';

document.addEventListener('DOMContentLoaded', () => {
    window.breakpoints = new Breakpoints();

    new Spinner().init();
    new Menu().init();
    new Cookie().init();
    new ConsentTracking().init();
    new Footer().init();
    new MiniCart(true).init();
    new MiniWishlist().init();
    new MiniAccount().init();
    new Newsletter().init();
    new CollapsibleItem().init();
    new Search().init();
    new SearchBar().init();
    new Validation().init();
    new CountrySelector().init();
    new Tooltip().init();
    new Buttons().init();
    new Select().init();
    new MultipleText().init();
    new FormFrame().init();
    new GtmTriggers().init();

    if (Resource.getSitePreference('popup', 'enableCountryPopup')) {
        new CountryPopup().init();
    }

    if (Resource.getSitePreference('wishlistConfigs', 'wishlistTileConfigs')) {
        new WishlistHeart().init();
    }
});

window.addEventListener('load', () => {
    setTimeout(() => { window.loadRecaptchaCustom(); }, 500);
});
