export default class CountrySelector {
    init() {
        $('body').on('click', '.country-selector a, .country-selector button', function (e) {
            e.preventDefault();
            const act = $('.country-selector').data('action') || $('.page').data('action');
            const localeCode = $(this).data('locale');
            const localeCurrencyCode = $(this).data('currencycode');
            const queryString = $('.page').data('querystring');
            const url = $('.country-selector').data('url');
            $.ajax({
                url,
                type: 'get',
                dataType: 'json',
                data: {
                    code: localeCode,
                    queryString,
                    CurrencyCode: localeCurrencyCode,
                    action: act,
                },
                success(response) {
                    $.spinner().stop();
                    if (response && response.redirectUrl) {
                        window.location.href = response.redirectUrl;
                    }
                },
                error() {
                    $.spinner().stop();
                },
            });
        });

        $('.navbar-header .country-selector').on('focusin', function () {
            $(this).addClass('show').children('.dropdown-menu').addClass('show');
        });
    }
}
