export function isLeapYear(year) {
    return new Date(year, 1, 29).getDate() === 29;
}

export function getDaysInMonth(year, monthIndex) {
    let yearIndex = year;

    if (Number.isNaN(year)) {
        yearIndex = 2000; // Leap year by default
    }

    if (Number.isNaN(monthIndex)) {
        return null;
    }

    return new Date(yearIndex, monthIndex + 1, 0).getDate();
}

export function isDateValid(date, year, monthIndex, day) {
    return (year === date.getFullYear() &&
        monthIndex === date.getMonth() &&
        day === date.getDate());
}

export function getMonthOptions(days, selected) {
    const options = [];

    for (let i = 1; i <= days; i++) {
        const option = new Option(i, i, i === selected, i === selected);
        options.push(option);
    }

    return $(options);
}

export default class DateSelect {
    constructor(date, year, month, day) {
        this.$date = $(date);

        this.selectors = {
            date,
            year,
            month,
            day,
        };

        this.$year = $(year);
        this.$month = $(month);
        this.$day = $(day);
    }

    init() {
        if (!this.$date.length || !this.$year.length || !this.$month.length || !this.$day.length) {
            return;
        }

        this.events();
    }

    events() {
        $('body').on('change', `${this.selectors.year},${this.selectors.month},${this.selectors.day}`, this.handleChange.bind(this));
    }

    handleChange() {
        this.day = parseInt(this.$day.val(), 10);
        this.month = parseInt(this.$month.val(), 10);
        this.year = parseInt(this.$year.val(), 10);
        this.date = new Date(Date.UTC(this.year, this.month, this.day));

        const valid = isDateValid(this.date, this.year, this.month, this.day);

        // If the date is valid, set onto the hidden field
        if (valid) {
            this.$date.val(this.date.valueOf());
        } else {
            this.$date.val(undefined);
        }

        // Validate days select against current month/year
        const maxDays = getDaysInMonth(this.year, this.month);
        const currentLastDay = parseInt(this.$day.find('option').last().val(), 10);

        if (maxDays && maxDays !== currentLastDay) {
            const $options = getMonthOptions(maxDays, this.day);
            this.$day.empty().append($options);
        }
    }
}
