import Cart from 'common/cart/cart';
import Panel from 'common/components/panel';
import Resource from 'common/util/Resource';
import { initPaypalButtonCart, initFakePaypalButtonCart } from 'common/paypal/cart/cart';
import { onProductsImpression, onPromosImpression, onProductClick, onPromoClick, onCartView } from 'common/seo/gtmEvents';
import { findProductInArrayObjects } from 'common/search/search';

let updateMiniCart = true;

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 */
export function displayMessageAndRemoveFromCart(data) {
    $.spinner().stop();
    const status = data.success ? 'alert-success' : 'alert-danger';

    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append('<div class="add-to-wishlist-messages "></div>');
    }
    $('.add-to-wishlist-messages')
        .append(`<div class="add-to-wishlist-alert text-center ${status}">${data.msg}</div>`);

    setTimeout(() => {
        $('.add-to-wishlist-messages').remove();
    }, 1500);
    const $targetElement = $(`a[data-pid="${data.pid}"]`).closest('.product-info').find('.remove-product');
    const itemToMove = {
        actionUrl: $targetElement.data('action'),
        productID: $targetElement.data('pid'),
        productName: $targetElement.data('name'),
        uuid: $targetElement.data('uuid'),
    };
    $('body').trigger('afterRemoveFromCart', itemToMove);
    setTimeout(() => {
        $('.cart.cart-page #removeProductModal').modal();
    }, 2000);
}

function pushEventsOnMinicartOpen() {
    onCartView(window.lineItemCtnrProducts, window.dataLayer);
    onProductsImpression(window.lineItemCtnrProducts, window.currency);
    onPromosImpression(window.lineItemCtnrPromo);
}

export default class MiniCart {
    constructor(openWhenEmpty = false) {
        this.openWhenEmpty = openWhenEmpty;
    }

    init() {
        const self = this;
        const cart = new Cart();
        cart.init();
        const minicart = new Panel('.panel--minicart', { loader: true });

        function loadAndShowMinicart(e) {
            const url = $('.minicart').data('action-url');
            const count = parseInt($('.minicart .minicart-quantity').text(), 10);
            if (window.breakpoints.atLeast('lg')) {
                $('.panel--minicart').css({
                    top: $('.page-header').hasClass('sticky') ? $('.nav').outerHeight() : $('.nav').outerHeight() + $('.nav').offset().top,
                });
            } else {
                $('.panel--minicart').css('top', 0);
            }
            if ((self.openWhenEmpty || count !== 0) && !minicart.isOpen()) {
                if (['click', 'touchstart'].indexOf(e.type) > -1) {
                    e.preventDefault();
                }

                if (e.type === 'product:afterAddToCart' && !window.breakpoints.atLeast('lg') && $(e.currentTarget).find('.page').data('action') === 'Search-Show') {
                    setTimeout(() => {
                        const mobileBackground = $('.mobileSize-background');
                        mobileBackground.each(function () {
                            $(this).removeClass('size-visible');
                        });
                        const tileMobileQuickActions = $('.product-tile__quick-actions');
                        tileMobileQuickActions.each(function () {
                            $(this).find('.product-tile__close').trigger('click');
                        });
                    }, 3000);
                    return;
                }

                if (!updateMiniCart) {
                    minicart.open();
                    pushEventsOnMinicartOpen();
                    // disable page scroll
                    $('html').addClass('overflow-hidden');
                    return;
                }

                if (!url) {
                    return;
                }

                minicart.open();
                // disable page scroll
                $('html').addClass('overflow-hidden');

                setTimeout(() => {
                    if (window.breakpoints.atLeast('lg')) {
                        const tileQuickActions = $('.product-tile__quick-actions');
                        tileQuickActions.each(function () {
                            $(this).find('.product-tile__close').trigger('click');
                        });
                    }
                }, 3000);

                $.get(url, (data) => {
                    minicart.$panel.empty().append(data);
                    updateMiniCart = false;

                    // paypal init
                    initPaypalButtonCart('.panel--minicart');
                    initFakePaypalButtonCart();

                    // amazonpay init

                    if ($('#AmazonPayButtonMiniCart').length) {
                        /* eslint-disable */
                        amazon.Pay.renderButton('#AmazonPayButtonMiniCart', {
                            merchantId: AmazonSitePreferences.AMAZON_MERCHANT_ID,
                            createCheckoutSession: {
                                url: AmazonURLs.createCheckoutSession,
                            },
                            ledgerCurrency: AmazonSitePreferences.AMAZON_CURRENCY,
                            checkoutLanguage: AmazonSitePreferences.AMAZON_CHECKOUT_LANGUAGE,
                            productType: AmazonSitePreferences.AMAZON_PRODUCT_TYPE,
                            sandbox: AmazonSitePreferences.AMAZON_SANDBOX_MODE,
                            placement: 'Cart',
                            buttonColor: 'LightGray',
                        });
                        /* eslint-enable */
                    }

                    $.spinner().stop();
                }).done(() => {
                    const style = document.createElement('style');
                    const stylePrefAmazon = Resource.getSitePreference('customAmazonStyle', 'amazonButtonCustomStyle');
                    style.innerHTML = stylePrefAmazon ? stylePrefAmazon.toString() : '';
                    const amazonPayButton = document.getElementById('AmazonPayButtonMiniCart');
                    if (amazonPayButton) amazonPayButton.shadowRoot.appendChild(style);

                    if ($('.has-applePayOnly') && $('.apple-pay-wrapper button') && $('.apple-pay-wrapper button').length === 0) {
                        $('.has-applePayOnly').addClass('d-none');
                    }
                });
            }
            pushEventsOnMinicartOpen();
        }

        $('.minicart').on('count:update', (event, count) => {
            const $quantity = $('.minicart .minicart-quantity');
            if (count && $.isNumeric(count.quantityTotal)) {
                $quantity.text(count.quantityTotal);

                if (!$quantity.is(':visible')) {
                    $quantity.addClass('animate');
                } else {
                    $quantity.removeClass('animate');
                }

                $quantity.show();

                $('.minicart .minicart-link').attr({
                    'aria-label': count.minicartCountOfItems,
                    title: count.minicartCountOfItems,
                });
            } else {
                $quantity.hide().removeClass('animate');
            }
        });

        $('.minicart').on('click touchstart', '.minicart-link', loadAndShowMinicart);

        // Close minicart when user leaves and does not re-enter
        $('.panel--minicart').on('click', '.minicart-close', () => {
            minicart.close();
        });

        // Close minicart when user leaves and does not re-enter
        $(document).on('cart:empty', () => {
            minicart.close();
        });

        $('body').on('change', '.minicart .quantity', function () {
            if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
                location.reload();
            }
        });

        $('body').on('product:afterAddToCart', (e) => {
            updateMiniCart = true;

            // Wait for afterAddToCart CTA animation, then show minicart
            window.setTimeout(() => {
                loadAndShowMinicart(e);
            }, 1000);
        });

        $('body').on('cart:update', () => {
            updateMiniCart = true;
        });

        this.moveToWishlist();

        $('body').on('panel:close', () => {
            // enable page scroll
            $('html').removeClass('overflow-hidden');
        });
        $(document).on('click', '.cart-page__line-items a:not(.checkout-btn), .panel--minicart .product-info a', (e) => {
            self.onClickProduct(e);
        });
    }

    moveToWishlist() {
        $('body').on('click', '.product-move .move', function (e) {
            e.preventDefault();
            const url = $(this).attr('href');
            const pid = $(this).data('pid');
            let optionId = $(this).closest('.product-info').find('.lineItem-options-values').data('option-id');
            let optionVal = $(this).closest('.product-info').find('.lineItem-options-values').data('value-id');
            optionId = optionId || null;
            optionVal = optionVal || null;
            if (!url || !pid) {
                return;
            }

            $.spinner().start();
            $.ajax({
                url,
                type: 'post',
                dataType: 'json',
                data: {
                    pid,
                    optionId,
                    optionVal,
                },
                success(data) {
                    displayMessageAndRemoveFromCart(data);
                },
                error(err) {
                    displayMessageAndRemoveFromCart(err);
                },
            });
        });
    }

    onClickProduct(e) {
        // Handle productClick

        if (!window.lineItemCtnrProducts.length) {
            return;
        }

        const currentProductUrl = e.target.href || e.target.parentNode.href;
        const productObject = findProductInArrayObjects(currentProductUrl, window.lineItemCtnrProducts[0]);
        const promoObject = findProductInArrayObjects(currentProductUrl, window.lineItemCtnrPromo[0]);
        if (Object.keys(promoObject).length !== 0) {
            onPromoClick([promoObject]);
        }
        onProductClick([productObject], currentProductUrl);

        e.stopPropagation();
    }
}
