export default class Buttons {
    constructor() {
        this.selector = '.btn.btn-primary:not(.no-hover):not(:disabled):not(.disabled):not(.btn-rollover), .btn.btn-secondary:not(.no-hover):not(:disabled):not(.disabled), .btn.btn-editorial:not(.no-hover):not(:disabled):not(.disabled)';
        this.$buttons = $(this.selector);
    }

    init() {
        if (!this.$buttons.length) return;

        // When leaving a button, add a ".leaving" class for 500ms
        $('body')
            .on('mouseleave', this.selector, (e) => {
                const $target = $(e.currentTarget);

                window.setTimeout(() => $target.addClass('leaving'), 100);
                window.setTimeout(() => $target.removeClass('leaving'), 1000);
            })
            .on('mouseenter', this.selector, (e) => {
                $(e.currentTarget).removeClass('leaving');
            });

        $('.btn-rollover').each((i, el) => {
            $(el).find('span').attr('data-rollover', $(el).text().trim());
        });
    }

    static flash($el, timer = 1000) {
        const $flash = $el.children('.btn-flash');
        const $orig = $flash.prev('span');

        if (!$flash.length || !$orig.length) {
            return;
        }

        $orig.addClass('hiding');

        window.setTimeout(() => {
            $flash.addClass('show').show();
            $orig.hide();
        }, 360);


        window.setTimeout(() => {
            $flash.removeClass('show');

            window.setTimeout(() => {
                $flash.hide();
                $orig.show().removeClass('hiding');
            }, 360);
        }, timer);
    }
}
