import Cookie from 'common/util/Cookie';
import Resource from 'common/util/Resource';

export default class CountryPopup {
    constructor() {
        this.$modal = $('#alertCountriesModal');
        this.cookieName = 'hide_countryPopup';
        this.cookieDuration = Resource.getSitePreference('cookiesConfigs', 'countryPopupCookieDuration');
        this.$consentTracking = $('#consent-tracking');
    }

    init() {
        this.events();
        this.close();
    }

    events() {
        if (!Cookie.exists(this.cookieName)) {
            this.open();
        }
    }

    open() {
        if (window.isPageDesignerMode) {
            return;
        }

        this.$modal.modal('show');

        this.$modal.on('shown.bs.modal', () => {
            $(document).off('focusin.modal');
        });
    }

    close() {
        this.$modal.on('hide.bs.modal', () => {
            Cookie.create(this.cookieName, this.cookieName, this.cookieDuration);
        });
    }
}
