export default class Cookie {
    static exists(name) {
        return document.cookie.split(';').filter((item) => item.trim().indexOf(`${name}=`) === 0).length;
    }

    static getValue(value) {
        const list = document.cookie.split('; ');
        const result = {};
        for (let i = 0; i < list.length; i++) {
            const cookie = list[i].split('=');
            result[cookie[0]] = cookie[1];
        }

        return result[value];
    }

    static create(name, value, days, path, httpOnly, secure) {
        let expires;
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = `; expires=${date.toGMTString()}`;
        } else {
            expires = '';
        }
        const pathValue = path || '/';
        const httpOnlyValue = httpOnly ? 'HttpOnly' : '';
        const secureValue = secure ? 'Secure' : '';
        document.cookie = `${name}=${value}${expires}; path=${pathValue}; ${secureValue}; ${httpOnlyValue}`;
    }
}
