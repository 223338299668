import FavouriteStore from 'common/profile/favouriteStore';
import formValidation from 'common/components/formValidation';
import ErrorNotification from 'common/components/errorNotification';
import DateSelect from 'common/components/dateSelect';
import { onCheckoutProgress, onFormSubmit } from 'common/seo/gtmEvents';

const errorNotification = new ErrorNotification();

export function channels() {
    $('.privacy__marketing__channels').on('click', 'a[href="#contactChannels"]', (e) => {
        e.preventDefault();
        $('#contactChannels.collapse').collapse('toggle');
    });
}

function triggerFormSubmit(e) {
    const formClass = $(e.target).attr('class');
    let loyalty;
    if ($(e.target).find('input[id*=\'loyalty-true\']:checked').length > 0) {
        loyalty = 'loyalty - acconsento';
    }
    if ($(e.target).find('input[id*=\'loyalty-false\']:checked').length > 0) {
        loyalty = 'loyalty -non acconsento';
    }
    onFormSubmit(formClass, loyalty);
}

export default class Login {
    init() {
        this.collapse();
        this.login();
        this.register();
        this.resetPassword();
        this.clearResetForm();
        this.date();
        new FavouriteStore().init();
        channels();
        this.onLogin();
    }

    onLogin() {
        if ($('.checkout-login-page').length > 0) { onCheckoutProgress(window.lineItemCtnrProducts, 'begin_checkout'); }
    }

    login() {
        $('form.login').submit(function (e) {
            triggerFormSubmit(e);
            const form = $(this);
            e.preventDefault();
            const url = form.attr('action');
            form.parents('section').spinner().start();
            $('form.login').trigger('login:submit', e);
            $.ajax({
                url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success(data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                        $('form.login').trigger('login:error', data);
                    } else {
                        $('form.login').trigger('login:success', data);
                        location.href = data.redirectUrl;
                    }
                },
                error(data) {
                    if (data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        $('form.login').trigger('login:error', data);
                        form.spinner().stop();
                    }
                },
            });
            return false;
        });
    }

    register() {
        $('form.registration').submit(function (e) {
            const form = $(this);
            e.preventDefault();
            const url = form.attr('action');
            form.spinner().start();
            $('form.registration').trigger('login:register', e);
            $.ajax({
                url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success(data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        location.href = data.redirectUrl;
                    }
                },
                error(err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        errorNotification.init($('.error-messaging'), err.responseJSON.errorMessage);
                    }

                    form.spinner().stop();
                },
            });
            return false;
        });
    }

    resetPassword() {
        $('.reset-password-form').submit(function (e) {
            const form = $(this);
            e.preventDefault();
            const url = form.attr('action');
            form.spinner().start();
            $('.reset-password-form').trigger('login:register', e);
            $.ajax({
                url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success(data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        $('.request-password-title').text(data.receivedMsgHeading);
                        $('.request-password-body').empty()
                            .append(`<p>${data.receivedMsgBody}</p>`);
                        if (!data.mobile) {
                            $('#submitEmailButton').text(data.buttonText)
                                .attr('data-dismiss', 'modal');
                        } else {
                            $('.send-email-btn').empty()
                                .html(`<a href="${
                                    data.returnUrl
                                }" class="btn btn-primary btn-block">${
                                    data.buttonText}</a>`
                                );
                        }
                    }
                },
                error() {
                    form.spinner().stop();
                },
            });
            return false;
        });
    }

    clearResetForm() {
        $('#login .modal').on('hidden.bs.modal', () => {
            $('#reset-password-email').val('');
            $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
        });
    }

    collapse() {
        function callback(target, show = true) {
            // Skip on marketing collapse toggle
            if ($(target).closest('.privacy__marketing').length) {
                return;
            }

            const $target = $(target).closest('.login-page__section').find('[data-toggle="collapse"]');
            if (show) {
                $target.removeClass('d-none').show();
            } else {
                $target.hide();
            }
        }

        $('.page .login-page')
            .on('hide.bs.collapse', (e) => {
                callback(e.target, true);
            })
            .on('show.bs.collapse', (e) => {
                callback(e.target, false);
            });
    }

    date() {
        const birthday = new DateSelect(
            '[name$="profile_customer_birthday"]',
            '[name$="profile_customer_birthday__year"]',
            '[name$="profile_customer_birthday__month"]',
            '[name$="profile_customer_birthday__day"]');

        birthday.init();
    }
}
